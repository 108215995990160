import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { ThunkAction as ThunkActionInterface, ThunkDispatch } from 'redux-thunk';
import { configureStore, Action, getDefaultMiddleware, AnyAction } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer, { RootReducer } from './rootReducer';
import { onStateInitialized } from 'index';

let StateProvider: FunctionComponent;

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type OnStateInitialized = (stateStore: typeof store) => void;
export const persistor = persistStore(store, undefined, () => {
  onStateInitialized(store);
});

const disablePersist = process.env.NODE_ENV !== 'development';
if (disablePersist) {
  persistor.pause();
  persistor.purge();
}

export const useStateProvider = () => {
  if (StateProvider) return StateProvider;

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const newRootReducer = require('./rootReducer').default;
      store.replaceReducer(newRootReducer);
    });
  }

  const StateProviderComponent: FunctionComponent = ({ children }) => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );

  StateProvider = StateProviderComponent;
  return StateProvider;
};

export type State = RootReducer;
export type Dispatch = ThunkDispatch<State, any, AnyAction>;
export type ThunkAction = ThunkActionInterface<void, State, null, Action<string>>;
