import styled from 'styled-components';

export const page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;
