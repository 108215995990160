import React, { useEffect } from 'react';
import { useTranslate } from 'Locale';
import { Actions, Back, Next } from 'Components/Layout/Actions';

import { Answers, SkinType } from './SkinType';

import * as styled from './styled';

const mapSkinType = ({ answers }: { answers: Answers }): SkinType => {
  const skinTypeTotals = Object.values(answers).reduce<Record<SkinType, number>>(
    (acc, skinType) => ({ ...acc, [skinType]: (acc[skinType] ?? 0) + 1 }),
    {} as Record<SkinType, number>,
  );

  const [resultSkinType] = (Object.entries(skinTypeTotals) as [SkinType, number][]).reduce(
    (prevSkinType, currentSkinType) => {
      const [, prevTotal] = prevSkinType;
      const [, currentTotal] = currentSkinType;
      return currentTotal > prevTotal ? currentSkinType : prevSkinType;
    },
  );

  return resultSkinType;
};

export const QuestionResult: React.FC<{
  onNextStep: () => void;
  onBackStep: () => void;
  onSelect: (skinType: SkinType) => void;
  answers: Answers;
}> = ({ onNextStep, onBackStep, answers, onSelect }) => {
  const [t, keys] = useTranslate();
  useEffect(() => {
    const skinType = mapSkinType({ answers });
    onSelect(skinType);
  }, [answers, onSelect]);

  const skinType = mapSkinType({ answers });
  return (
    <>
      <styled.result>
        <styled.resultText>{t(keys.skinType.result.text)}</styled.resultText>
        <styled.resultSkinType>{t(keys.skinType.options[skinType].name)}</styled.resultSkinType>
      </styled.result>
      <Actions>
        <Back onClick={onBackStep} />
        <Next onClick={onNextStep} />
      </Actions>
    </>
  );
};
