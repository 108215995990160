import styled from 'styled-components';

export const animation = styled.div`
  height: 40px;
  position: relative;
  top: -20px;
  .loader {
    font-size: 18px !important;
  }
`;

export const loadingWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fbfaf9;
  margin-top: 30px;
`;

export const filters = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  flex-shrink: 0;
`;

export const actions = styled.div`
  padding: 0 15px;
`;

export const chips = styled.div`
  flex: 1;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
`;
