import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const wrapper = styled.div<{ selected: boolean }>`
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0 1px 6px 1px ${({ selected, theme }) => (selected ? theme.colors.primaryDark : 'rgba(64, 64, 64, 0.15)')};
  transition: opacity 0.4s ease;
  width: 140px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 9px;
  padding: 20px;
  display: flex;
  box-sizing: content-box;
  * {
    box-sizing: content-box;
  }
`;

export const metric = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  height: 40px;
`;

export const iconWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const icon = styled('div')`
  height: 80px;
  width: 100%;
`;

export const label = styled('div')<{ color: string }>`
  ${heavyFont}
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: ${({ color }) => color};
`;
