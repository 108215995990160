import React, { useEffect } from 'react';
import { useGlobalState, navigation } from 'State';
import * as analyticsService from 'Services/analytics';
import Landing from './Landing';
import Gender from './Gender';
import SkinType from './SkinType';
import SkinConcerns from './SkinConcerns';
import Location from './Location';
import Age from './Age';
import SelfieInstructions from './SelfieInstructions';
import Selfie from './Selfie';
import Result from './Result';
import RecommendedProducts from './RecommendedProducts';

export const Router = () => {
  const currentPage = useGlobalState(navigation.currentPageSelector);
  useEffect(() => {
    analyticsService.dispatch({ type: 'pageChange', payload: { name: currentPage } });
  }, [currentPage]);

  switch (currentPage) {
    case 'landing':
      return <Landing />;

    case 'gender':
      return <Gender />;

    case 'skintype':
      return <SkinType />;

    case 'skinConcerns':
      return <SkinConcerns />;

    case 'location':
      return <Location />;

    case 'age':
      return <Age />;

    case 'selfieInstructions':
      return <SelfieInstructions />;

    case 'selfie':
      return <Selfie />;

    case 'result':
      return <Result />;

    case 'recommendedProducts':
      return <RecommendedProducts />;

    default:
      return null;
  }
};
