const baseURL = {
  prod: 'https://partner.revieve.com',
  test: 'https://partner-test.revieve.com',
  local: 'https://partner-test.revieve.com',
};

type Environment = keyof typeof baseURL;

interface WeatherResponse {
  accuracy: number;
  area?: string;
  city?: string;
  country: string;
  lat: number;
  lon: number;
  uv_index?: number;
  pollution?: number;
  humidity?: number;
}

export const getWeather = async ({
  environment = 'prod',
  area,
  city,
  country,
  lat,
  lon,
}: {
  environment?: Environment;
  area?: string;
  city?: string;
  country?: string;
  lat?: number;
  lon?: number;
}) => {
  const response = await fetch(`${baseURL[environment]}/api/functions/weather`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
    body: JSON.stringify({ area, city, country, lat, lon }),
  });
  const data: { result: WeatherResponse } = await response.json();
  const { uv_index: uvIndex, pollution, humidity } = data.result;
  return { uvIndex, pollution, humidity: humidity ? Math.round(humidity) : humidity };
};

interface Location {
  lat: number;
  lon: number;
  name: string;
}
