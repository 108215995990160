import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Icon } from 'Components/Icon';
import oval from './oval.png';

import * as styled from './styled';

export const TakeSelfie: React.FC<{
  onCancel: () => void;
  onError: () => void;
  onTakeSelfie: (image: string) => void;
}> = ({ onTakeSelfie, onError, onCancel }) => {
  const [loaded, setLoaded] = useState(false);
  const [t, keys] = useTranslate();
  const webcam = useRef<any>(null);
  const [mode, setMode] = useState<'user' | 'environment'>('user');

  const handleUserMediaStarts = () => setLoaded(true);

  return (
    <>
      <styled.videoWrapper>
        <Webcam
          key={mode}
          onUserMedia={handleUserMediaStarts}
          onUserMediaError={onError}
          audio={false}
          ref={webcam}
          screenshotFormat="image/jpeg"
          width={1280}
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: mode,
          }}
          minScreenshotWidth={800}
          minScreenshotHeight={800}
          screenshotQuality={1}
          mirrored={mode === 'user'}
        />
      </styled.videoWrapper>
      <Page>
        <NavBar />
        <Content>
          {loaded && (
            <>
              <styled.selfieGuide src={oval} alt="selfie guide" />
              <styled.contentWrapper>
                <styled.cancel onClick={onCancel}>{t(keys.selfie.takeSelfie.cancel)}</styled.cancel>
                <styled.click>
                  <styled.clickIcon
                    onClick={() => {
                      const image = webcam?.current?.getScreenshot && (webcam?.current?.getScreenshot() as string);
                      if (image) onTakeSelfie(image);
                    }}
                  >
                    <Icon name="cameraCapture" />
                  </styled.clickIcon>
                </styled.click>
                <styled.changeCamera onClick={() => setMode(mode === 'user' ? 'environment' : 'user')}>
                  <styled.switchCameraIcon>
                    <Icon name="switchCamera" />
                  </styled.switchCameraIcon>
                </styled.changeCamera>
              </styled.contentWrapper>
            </>
          )}
        </Content>
      </Page>
    </>
  );
};
