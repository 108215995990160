import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const animation = styled.div`
  height: 50px;
  height: 40px;
  position: relative;
  top: -40px;
`;

export const loadingWrapper = styled.div`
  ${heavyFont}
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  color: #000000;
  height: 268px;
`;
