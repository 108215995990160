import * as navigationStore from './navigation';
import * as userInputDataStore from './userInputData';
import * as selfieStore from './selfie';
import * as recommendedProductsStore from './recommendedProducts';

export const navigation = navigationStore;
export const userInputData = userInputDataStore;
export const selfie = selfieStore;
export const recommendedProducts = recommendedProductsStore;

export * from './hooks';
export * from './state';
