import React, { FunctionComponent } from 'react';
import * as styled from './styled';

import { ReactComponent as forwardArrow } from './forward-arrow.svg';
import { ReactComponent as backwardArrow } from './back-arrow.svg';
import { ReactComponent as face } from './face.svg';
import { ReactComponent as uv } from './uv.svg';
import { ReactComponent as humidity } from './humidity.svg';
import { ReactComponent as pollution } from './pollution.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as checkOn } from './check_on.svg';
import { ReactComponent as checkOff } from './check_off.svg';
import { ReactComponent as radioOn } from './radio_on.svg';
import { ReactComponent as radioOff } from './radio_off.svg';
import { ReactComponent as back } from './back.svg';
import { ReactComponent as switchCamera } from './switch-camera.svg';
import { ReactComponent as cameraCapture } from './camera_capture.svg';
import { ReactComponent as hyperpigmentation } from './hyperpigmentation.svg';
import { ReactComponent as wrinkles } from './wrinkles.svg';
import { ReactComponent as redness } from './redness.svg';
import { ReactComponent as texture } from './texture.svg';
import { ReactComponent as skinShine } from './skin-shine.svg';
import { ReactComponent as acne } from './acne.svg';
import { ReactComponent as smoothness } from './smoothness.svg';
import { ReactComponent as eyebags } from './eyebags.svg';
import { ReactComponent as darkcircles } from './darkcircles.svg';
import { ReactComponent as arrowTop } from './arrow-top.svg';
import { ReactComponent as arrowBottom } from './arrow-bottom.svg';

export const ICONS: Record<DefaultIconName, FunctionComponent> = {
  forwardArrow,
  backwardArrow,
  face,
  uv,
  humidity,
  pollution,
  close,
  checkOn,
  checkOff,
  radioOn,
  radioOff,
  back,
  cameraCapture,
  switchCamera,
  overall: hyperpigmentation,
  hyperpigmentation,
  wrinkles,
  redness,
  texture,
  skinShine,
  acne,
  smoothness,
  eyebags,
  darkcircles,
  arrowTop,
  arrowBottom,
};

export type DefaultIconName =
  | 'forwardArrow'
  | 'backwardArrow'
  | 'face'
  | 'uv'
  | 'humidity'
  | 'pollution'
  | 'close'
  | 'checkOn'
  | 'checkOff'
  | 'radioOn'
  | 'radioOff'
  | 'back'
  | 'cameraCapture'
  | 'switchCamera'
  | 'overall'
  | 'hyperpigmentation'
  | 'wrinkles'
  | 'redness'
  | 'texture'
  | 'skinShine'
  | 'acne'
  | 'smoothness'
  | 'eyebags'
  | 'darkcircles'
  | 'arrowTop'
  | 'arrowBottom';

export interface IconProps extends styled.StyledIcon {
  name: DefaultIconName;
}

export const Icon: FunctionComponent<IconProps> = ({ name, ...styledProps }) => {
  const IconComponent = ICONS[name];
  if (!IconComponent) return null;
  return (
    <styled.wrapper {...styledProps}>
      <IconComponent />
    </styled.wrapper>
  );
};
