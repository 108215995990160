import React from 'react';
import { useAction, useGlobalState, navigation, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Title } from 'Components/Title';
import { Select } from 'Components/Select';
import { Actions, Next, Back } from 'Components/Layout/Actions';

import * as styled from './styled';

const Gender = () => {
  const [t, keys] = useTranslate();
  const gender = useGlobalState(({ userInputData }) => userInputData.gender);
  const selectGender = useAction(userInputData.selectGender);
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);

  const options = [
    { label: t(keys.gender.female), value: 'female' },
    { label: t(keys.gender.male), value: 'male' },
  ];

  return (
    <Page>
      <NavBar />
      <Content>
        <Title step={2}>{t(keys.gender.title)}</Title>

        <styled.questionSelector>
          <Select values={[gender]} onSelect={selectGender} layout="normal">
            {options.map(({ label, value }) => (
              <Select.Button key={value} value={value}>
                {label}
              </Select.Button>
            ))}
          </Select>
        </styled.questionSelector>

        <Actions>
          <Back onClick={goBack} />
          <Next onClick={goNext} disabled={gender === undefined} />
        </Actions>
      </Content>
    </Page>
  );
};

export default Gender;
