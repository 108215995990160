import React from 'react';
import { useSelectActions } from '../context';

import * as styled from './styled';

export interface ImageProps {
  value: string;
  image: string;
}

const Image: React.FC<ImageProps> = ({ value, image }) => {
  const { onSelect, onUnselect, selectedValues } = useSelectActions();
  const selected = selectedValues.includes(value);
  const handleClick = () => {
    if (!selected) onSelect(value);
    else onUnselect(value);
  };
  return (
    <styled.wrapper>
      <styled.image onClick={handleClick} src={image} alt={value} selected={selected} />
    </styled.wrapper>
  );
};

export default Image;
