import GoogleMaps from 'google-maps-api';
const mapsKey = 'AIzaSyDMFjIWuZ4zs9s0RtY3jq9F0vNnBTQ-TBQ';

let googleMapsInstance: any;
const getGoogleMaps = async () => {
  if (!googleMapsInstance) {
    googleMapsInstance = await GoogleMaps(mapsKey, ['places'])(); // eslint-disable-line require-atomic-updates
  }
  return googleMapsInstance;
};

let geocoder: any;
const getGeoCoder = async () => {
  if (!geocoder) {
    const googleMaps = await getGoogleMaps();
    geocoder = new googleMaps.Geocoder(); // eslint-disable-line require-atomic-updates
  }
  return geocoder;
};

let autocompleter: any;
const getAutocompleter = async () => {
  if (!autocompleter) {
    const googleMaps = await getGoogleMaps();
    autocompleter = new googleMaps.places.AutocompleteService(); // eslint-disable-line require-atomic-updates
  }
  return autocompleter;
};

let autocompleterSessionToken: any;
const getAutocompleterSessionToken = async () => {
  if (!autocompleterSessionToken) {
    const googleMaps = await getGoogleMaps();
    autocompleterSessionToken = new googleMaps.places.AutocompleteSessionToken(); // eslint-disable-line require-atomic-updates
  }
  return autocompleterSessionToken;
};

export const getPlaceIdFromLocation = async ({ lat, lon }: { lat: number; lon: number }): Promise<string> => {
  const googleMaps = await getGoogleMaps();
  const latLng = new googleMaps.LatLng(lat, lon);
  const geoCoder = await getGeoCoder();
  return new Promise(resolve => {
    geoCoder.geocode({ latLng }, (results: any, status: any) => {
      if (status === googleMaps.GeocoderStatus.OK) {
        resolve(results[0].place_id);
      }
    });
  });
};

interface Info {
  area?: string;
  city?: string;
  country?: string;
  lat: number;
  lon: number;
}

export const getInfoFromPlaceId = async ({ placeId }: { placeId: string }): Promise<Info> => {
  const googleMaps = await getGoogleMaps();
  const geoCoder = await getGeoCoder();
  return new Promise(resolve => {
    geoCoder.geocode({ placeId }, (results: any, status: any) => {
      if (status === googleMaps.GeocoderStatus.OK) {
        const { geometry, address_components: addressComponents } = results[0];
        const addressInfo: Info = addressComponents.reduce((acc: any, { types: [type], long_name: name }: any) => {
          switch (type) {
            case 'locality':
              return { ...acc, city: name };
            case 'administrative_area_level_2':
              return { ...acc, area: name };
            case 'country':
              return { ...acc, country: name };
            default:
              return acc;
          }
        }, {});
        resolve({ ...addressInfo, lat: geometry.location.lat(), lon: geometry.location.lng() });
      }
    });
  });
};

interface Place {
  description: string;
  id: string;
  place_id: string;
}

interface SuggestedPlace {
  description: string;
  placeId: string;
}

export const getGeoSuggestedPlaces = async (search: string): Promise<SuggestedPlace[]> => {
  if (!search) return [];
  const autocompleter = await getAutocompleter();
  const sessionToken = await getAutocompleterSessionToken();
  return new Promise(resolve => {
    autocompleter.getPlacePredictions({ input: search, sessionToken }, (suggestedPlaces: Place[]) => {
      const places = (suggestedPlaces || []).map(({ description, place_id: placeId }) => ({ description, placeId }));
      resolve(places);
    });
  });
};
