export type Category =
  | 'default'
  | 'Cleanser'
  | 'Micellar Water'
  | 'Cleansing Wipes'
  | 'Makeup Remover'
  | 'Exfoliator'
  | 'Exfoliator Pads'
  | 'Face Mask'
  | 'Sheet Mask'
  | 'Toner'
  | 'Toner Pads'
  | 'Lotion'
  | 'Eye Cream'
  | 'Eye Serum'
  | 'Eye Treatment'
  | 'Treatments'
  | 'Acne treatments'
  | 'Dark spot corrector'
  | 'Eye Mask'
  | 'Pore Strips'
  | 'Face Oil'
  | 'Serum'
  | 'Face Mist'
  | 'Essence'
  | 'Moisturizer'
  | 'Gift Set'
  | 'Night Cream'
  | 'Night Face Mask'
  | 'Sunscreen'
  | 'Sunscreen_0'
  | 'Sunscreen_1'
  | 'Sunscreen_2';

export type Routine = 'cleanse' | 'treat' | 'moisturizeDay' | 'moisturizeNight' | 'protect';
export type PriceGroup = 'normal';

type RoutineConfiguration = Partial<Record<string, Category[]>>;
type Routines = { name: Routine; configurations: Record<string, RoutineConfiguration> }[];
interface PriceConfiguration extends Record<PriceGroup, [number, number]> {}
type PriceGroups = Partial<Record<Category, PriceConfiguration>>;

export const routines: Routines = [
  {
    name: 'cleanse',
    configurations: {
      skinType: {
        default: [
          'Cleanser',
          'Micellar Water',
          'Cleansing Wipes',
          'Makeup Remover',
          'Exfoliator',
          'Exfoliator Pads',
          'Face Mask',
          'Sheet Mask',
          'Toner',
          'Toner Pads',
          'Lotion',
        ],
      },
    },
  },
  {
    name: 'treat',
    configurations: {
      skinType: {
        default: [
          'Eye Cream',
          'Eye Serum',
          'Eye Treatment',
          'Treatments',
          'Acne treatments',
          'Dark spot corrector',
          'Eye Mask',
          'Sheet Mask',
          'Pore Strips',
          'Face Oil',
          'Serum',
          'Face Mist',
          'Essence',
        ],
      },
    },
  },
  {
    name: 'moisturizeDay',
    configurations: {
      skinType: {
        default: ['Moisturizer', 'Gift Set'],
      },
    },
  },
  {
    name: 'moisturizeNight',
    configurations: {
      skinType: {
        default: ['Night Cream', 'Night Face Mask'],
      },
    },
  },
  {
    name: 'protect',
    configurations: {
      skinType: {
        default: ['Sunscreen', 'Sunscreen', 'Sunscreen', 'Face Mist'],
        default_mapper: ['Sunscreen_0', 'Sunscreen_1', 'Sunscreen_2', 'Face Mist'],
      },
    },
  },
];

export const priceGroups: PriceGroups = {
  default: {
    normal: [1, 99999],
  },
};
