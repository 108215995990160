import styled from 'styled-components';

export const inputFile = styled('input')`
  visibility: hidden;
  display: none;
`;

export const instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const text = styled.div`
  line-height: 42px;
`;

export const tips = styled.ul`
  margin-top: 30px;
`;

export const tip = styled.li`
  line-height: 34px;
  margin-bottom: 15px;
`;

export const actions = styled.div`
  display: flex;
  justify-content: space-between;
`;
