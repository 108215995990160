import React from 'react';
import { useAction, navigation, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Title } from 'Components/Title';
import { Actions, Next, Back } from 'Components/Layout/Actions';
import { LocationInput } from 'Components/LocationInput';

import * as styled from './styled';

const SkinType = () => {
  const [t, keys] = useTranslate();
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);
  const selectLocation = useAction(userInputData.selectLocation);
  return (
    <Page>
      <NavBar />
      <Content>
        <Title step={4}>{t(keys.location.title)}</Title>

        <styled.weather>
          <LocationInput
            environment="test"
            translations={{
              placeholder: t(keys.location.weather.placeholder),
              uvIndex: t(keys.location.weather.uvIndex),
              humidity: t(keys.location.weather.humidity),
              pollution: t(keys.location.weather.pollution),
            }}
            onChange={selectLocation}
          />
        </styled.weather>

        <Actions>
          <Back onClick={goBack} />
          <Next onClick={goNext} />
        </Actions>
      </Content>
    </Page>
  );
};

export default SkinType;
