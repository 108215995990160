import React from 'react';
import { SnapList, SnapItem } from 'react-snaplist-carousel';
import * as recommendedProducts from 'State/recommendedProducts';
import { useTranslate } from 'Locale';
import { Product } from './Product';

import * as styled from './styled';

export const Routine: React.FC<{ name: recommendedProducts.Routine; products: recommendedProducts.Product[] }> = ({
  name,
  products = [],
}) => {
  const [t, keys] = useTranslate();

  return (
    <styled.wrapper>
      <styled.title>{t(keys.recommendedProducts[name])}</styled.title>

      <SnapList>
        {products.map((product, index) =>
          product ? (
            <SnapItem
              key={product.id}
              paddingLeft={'15px'}
              paddingRight={index === products.length - 1 ? '15px' : undefined}
              snapAlign="start"
            >
              <Product product={product} bestMatch={index === 0} />
            </SnapItem>
          ) : null,
        )}
      </SnapList>
    </styled.wrapper>
  );
};
