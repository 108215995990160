import React from 'react';
import { useTranslate } from 'Locale';

import * as styled from './styled';

export const Analysing: React.FC<{ image: string }> = ({ image }) => {
  const [t, keys] = useTranslate();
  return (
    <>
      <styled.image src={image} alt="selfie" />
      <styled.loadingWrapper>
        <styled.animation>
          <div className="loader__wrapper">
            <div className="loader">&nbsp;</div>
          </div>
        </styled.animation>
        {t(keys.selfie.analyzing.text)}
      </styled.loadingWrapper>
      ∏
    </>
  );
};
