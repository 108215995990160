import styled from 'styled-components';

export const wrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #000000;
  max-width: 100%;
  height: 268px;
`;

export const cvResultsWrapper = styled('div')`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  pointer-events: none;
`;

export const cvResults = styled('div')<{ image?: string | null }>`
  overflow: hidden;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
`;

export const metrics = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const actions = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 20px 50px;
`;
