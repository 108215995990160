import React from 'react';
import { useAction, useGlobalState, navigation, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Title } from 'Components/Title';
import { Actions, Next, Back } from 'Components/Layout/Actions';
import { Select } from 'Components/Select';

import * as styled from './styled';
// import { goBack } from 'State/navigation';

const SkinType = () => {
  const [t, keys] = useTranslate();
  const skinConcern = useGlobalState(({ userInputData }) => userInputData.skinConcerns);
  const selectSkinConcern = useAction(userInputData.selectSkinConcern);
  const unselectSkinConcern = useAction(userInputData.unselectSkinConcern);
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);

  const options = [
    { label: t(keys.skinConcerns.wrinkles), value: 'wrinkles' },
    { label: t(keys.skinConcerns.eyebags), value: 'eyebags' },
    { label: t(keys.skinConcerns.redness), value: 'redness' },
    { label: t(keys.skinConcerns.dull), value: 'dull' },
    { label: t(keys.skinConcerns.aging), value: 'aging' },
    { label: t(keys.skinConcerns.acne), value: 'acne' },
    { label: t(keys.skinConcerns.unevenTone), value: 'unevenTone' },
    { label: t(keys.skinConcerns.pores), value: 'pores' },
    { label: t(keys.skinConcerns.lossOfFirmess), value: 'lossOfFirmess' },
  ];

  return (
    <Page>
      <NavBar />
      <Content>
        <Title step={1}>{t(keys.skinConcerns.title)}</Title>
        <styled.question>{t(keys.skinConcerns.question)}</styled.question>
        <styled.questionSelector>
          <Select values={skinConcern} onSelect={selectSkinConcern} onUnselect={unselectSkinConcern} layout="normal">
            {options.map(({ label, value }) => (
              <Select.Button key={value} value={value}>
                {label}
              </Select.Button>
            ))}
          </Select>
        </styled.questionSelector>
        <Actions>
          <Back onClick={goBack} />
          <Next onClick={goNext} disabled={skinConcern.length === 0} />
        </Actions>
      </Content>
    </Page>
  );
};

export default SkinType;
