import styled from 'styled-components';
import { heavyFont, regularFont } from 'Styles/fonts';

export const wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  label {
    ${regularFont}
    font-size: 18px;
    position: absolute;
    left: 20px;
    top: 10px;
  }
`;
export const inputWrapper = styled.div`
  width: 470px;
  cursor: pointer;
  border: solid 2px #cfcfcf;
  border-radius: 10px;
  background-color: #fbfaf9;
  padding: 20px 17px;
  position: relative;
`;

export const input = styled.input`
  ${heavyFont}
  font-size: 28px;
  width: 100%;
  padding-top: 20px;
  padding-left: 5px;
  color: #404040;
  background-color: #fbfaf9;
  border: none;
`;

export const suggestedPlaces = styled.div`
  position: absolute;
  width: 470px;
  top: 100px;
  background-color: white;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const suggestedPlace = styled.div`
  ${heavyFont}
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 18px;
  &:hover {
    background-color: #efefef;
  }
`;

export const info = styled.div`
  margin: 6px 0;
  text-align: center;
`;
