import styled from 'styled-components';

export const videoWrapper = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000000;
  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const selfieGuide = styled.img`
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
`;

export const contentWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 133px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const cancel = styled.div`
  flex: 1;
  color: #ffffff;
  font-size: 32px;
`;

export const click = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const clickIcon = styled.div`
  width: 80px;
  height: 80px;
  color: #ffffff;
`;

export const switchCameraIcon = styled.div`
  width: 60px;
  height: 60px;
  color: #ffffff;
`;

export const changeCamera = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
