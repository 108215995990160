import styled, { DefaultTheme } from 'styled-components';
import { heavyFont, lightFont } from 'Styles/fonts';

export const wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  color: #333333;
`;

export const item = styled.div`
  flex: 1;
  margin: 1px;
  padding: 6px;
  border-radius: 6px;
`;

export const value = styled.div<{ theme: DefaultTheme }>`
  ${lightFont}
  margin: 3px 0 6px;
  text-align: center;
  font-size: 22px;
`;

export const icon = styled.div`
  height: 100px;
  margin: 50px 10px;
`;

export const label = styled.div<{ theme: DefaultTheme }>`
  ${heavyFont}
  color: #404040;
  font-size: 28px;
  margin: 6px 0 6px;
  text-align: center;
`;

export const description = styled.div<{ theme: DefaultTheme }>`
  font-size: 0.7em;
  text-align: center;
`;
