import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const courtine = styled.div<{ show: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? null : 'none')};
  transition: opacity 250ms ease-out;
`;

export const filters = styled.div<{ show: boolean }>`
  background-color: #ffffff;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  transform: translateX(${({ show }) => (show ? '0' : '110%')});
  transition: transform 350ms ease-out;
  display: flex;
  flex-direction: column;
`;

export const close = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  padding: 40px 40px 0;
`;

export const closeIcon = styled.div`
  width: 25px;
`;

export const content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

export const actions = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const title = styled.div`
  ${heavyFont}
  color: #000000;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 15px;
  border-bottom: solid 1px #cfcfcf;
`;

export const sections = styled.div`
  flex: 1;
  overflow-y: auto;
  flex-basis: 0;
`;

export const section = styled.div`
  border-bottom: solid 1px #cfcfcf;
  padding: 15px;
`;

export const sectionHeader = styled.div`
  display: flex;
`;

export const sectionTitle = styled.div<{ openned: boolean }>`
  flex: 1;
  ${heavyFont}
  color: ${({ openned, theme }) => (openned ? '#000000' : theme.colors.primaryDark)};
  font-size: 16px;
  line-height: 20px;
`;

export const sectionIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-top: -5px;
  color: #8a8a8a;
`;

export const sectionOptions = styled.div`
  padding-top: 5px;
`;

export const tooltip = styled(ReactTooltip)`
  line-height: 1.2em;
  padding: 10px;
`;
