import React from 'react';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';

import * as styled from './styled';

export const Confirmation: React.FC<{
  image: string;
  warnings: string[];
  onRetry: () => void;
  onContinue: () => void;
}> = ({ image, warnings, onRetry, onContinue }) => {
  const [t, keys] = useTranslate();
  return (
    <>
      <styled.image src={image} alt="selfie" />
      <Page>
        <NavBar />
        <Content>
          <styled.contentWrapper>
            {warnings.length > 0 &&
              warnings.map((warning, key) => <styled.message key={key}>{warning}</styled.message>)}
            <styled.rowActions>
              <styled.link onClick={onRetry}>{t(keys.selfie.error.retry)}</styled.link>
              <styled.link color="primary" onClick={onContinue}>
                {t(keys.common.next)}
              </styled.link>
            </styled.rowActions>
          </styled.contentWrapper>
        </Content>
      </Page>
    </>
  );
};
