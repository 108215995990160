import styled from 'styled-components';
import { boldFont, lightFont } from 'Styles/fonts';

export const title = styled.div`
  ${boldFont}
  color: #000000;
  margin-top: 40px;
  padding: 0 10px;
`;

export const finding = styled.div`
  ${lightFont}
  margin: 20px 0;
`;

export const content = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 9px 10px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
  /* overflow-x: hidden; */
`;
