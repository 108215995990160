import styled, { css } from 'styled-components';

const contentStyles = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const paddingStyles = css`
  padding: 0 15px;
`;

export const content = styled.div`
  ${contentStyles}
  ${paddingStyles}
`;

export const fullScreenContent = styled.div`
  ${contentStyles}
`;

export const paddingContent = styled.div`
  ${paddingStyles}
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
