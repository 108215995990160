import { Translations } from './keys';

const translations: Translations = {
  language: 'English',
  common: {
    next: 'Next',
    back: 'Back',
  },
  landing: {
    intro: 'Skin care that works for you.',
    detail1: '1. Answer a few questions',
    detail2: '2. Take a selfie',
    detail3: '3. Get recommendations',
    start: 'Get started',
    termns: {
      link: 'terms and conditions',
      title: 'Terms and conditions',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdu lorem in metus suscipit, ut ullamcorper velit condimentum. Phasellus interdum sit lorem in metus suscipit, ut ullamcorper velit condime.ectetur adipiscing elit. Phasellus interdur lorem in metus.',
    },
  },
  gender: {
    title: 'I am a…',
    female: 'Woman',
    male: 'Man',
  },
  skinType: {
    title: 'Skin type',
    options: {
      sensitive: {
        name: 'Sensitive',
      },
      dry: {
        name: 'Dry',
      },
      oily: {
        name: 'Oily',
      },
      normal: {
        name: 'Normal',
      },
      combination: {
        name: 'Combination',
      },
      unknown: {
        name: 'Not sure',
      },
    },
    questions: {
      skinFeel: {
        text: 'In general, how does your skin feel?',
        options: {
          dry: 'Dry and tense',
          oily: 'Oily',
          normal: 'Soft and natural',
          combination: 'Oily on T-Zone and normal on cheeks',
        },
      },
      pores: {
        text: 'What size are your pores?',
        options: {
          dry: 'Small',
          oily: 'Large',
          normal: 'In-between',
          sensitive: 'Larger on forehead, nose and jaw. Smaller elsewhere.',
          combination: "Unsure, but I've noticed surface blood vessels",
        },
      },
      afternoonFeel: {
        text: 'How does your skin feel in the afternoon?',
        options: {
          dry: 'Dry and tense',
          oily: 'Oily',
          normal: 'No change',
          combination: 'Oily on T-Zone only',
        },
      },
      acne: {
        text: 'How often do you get pimples or acne?',
        options: {
          dry: 'Never',
          oily: 'Daily or weekly',
          normal: 'About once a month',
          combination: 'Rarely',
          sensitive: 'Rarely, but mostly on forehead, nose or chin',
        },
      },
      morningFeel: {
        text: 'How does your skin feel in the morning, before using any skin care products?',
        options: {
          dry: 'Dry and tense',
          oily: 'Oily, with visible pores',
          normal: 'Soft and natural',
          combination: 'Normal T-Zone with dry and tense cheeks',
        },
      },
    },
    result: {
      text: 'Your skintype is:',
    },
  },
  skinConcerns: {
    title: 'Skin concerns',
    question: 'Select all that apply.',
    wrinkles: 'Fine lines/wrinkles',
    eyebags: 'Eye puffiness',
    redness: 'Redness',
    dull: 'Dull skin',
    aging: 'Aging',
    acne: 'Acne',
    unevenTone: 'Uneven tone',
    pores: 'Visible pores',
    lossOfFirmess: 'Loss of firmness',
  },
  location: {
    title: 'Location',
    weather: {
      placeholder: 'Search location',
      uvIndex: 'UV index',
      humidity: 'Humidity',
      pollution: 'Pollution',
    },
  },
  selfieInstructions: {
    title: 'Photo',
    text: 'To help us analyze your skin accurately, please:',
    takeSelfie: 'Take photo',
    skipSelfie: 'Skip photo',
    removeGlasses: 'Remove glasses',
    pullBackHair: 'Pull back hair',
    removeMakeup: 'Remove makeup, if possible',
    goodLight: 'Ensure good lighting',
    lookAtCamera: 'Face camera directly',
    neutralExpresion: 'Keep a natural expression',
  },
  selfie: {
    question: {
      title: 'Matching Conditions',
      eyebags: 'Eye puffiness',
      darkSpots: 'Dark spots',
      wrinkles: 'Wrinkles',
    },
    takeSelfie: {
      cancel: 'Cancel',
    },
    error: {
      retry: 'Retry',
    },
    analyzing: {
      text: 'Analyzing your skin',
    },
    messages: {
      CV_CODE_NO_CAMERA: 'No camera available',
      CV_CODE_100: "Oops, this doesn't seem to be a color selfie. Please retake your selfie as a color-selfie",
      CV_CODE_101:
        'Oops, the resolution of the image was too small for us to analyze. We need an image of at least 600x600 pixels (any regular smartphone camera image will do)',
      CV_CODE_102:
        'There seems to be something wrong with the proportions of your image. Try closer to or further away from the camera so that your full face is visible.',
      CV_CODE_103:
        'Your selfie is quite dark. We can analyze it but better light would help to get more accurate analysis.',
      CV_CODE_104: 'Oh no, there seems to be too much light in your selfie. Try finding a spot with less direct light.',
      CV_CODE_201: "Hmm. We weren't able to find a face from your selfie. Can you try again?",
      CV_CODE_202: 'Multiple faces detected. Only one face is analyzed (see results for which :)',
      CV_CODE_203:
        'Hmm. We are not quite sure there was a face in the selfie. But we were tried to analyse what we believe to be a face.',
      CV_CODE_204: "Hmm there doesn't seem to be a face well visible. Please re-take your selfie",
      CV_CODE_205:
        'The picture is taken too close to the camera. As a result, the analysis might be inaccurate. Please take the picture further away from the camera.',
      CV_CODE_301: 'Your face is a bit too far from the camera, please take a photo closer to your face.',
      CV_CODE_302: 'Face is too much tilted to the side. Try again please, but this time face straight to the camera.',
      CV_CODE_303: 'Face is possibly tilted to the side. For best accuracy face straight towards the camera.',
      CV_CODE_304: 'Face is too much tilted down. Face straight at the camera.',
      CV_CODE_305:
        'Face is possibly tilted down. For best accuracy of the analysis, please face straight towards the camera.',
      CV_CODE_306:
        'Face is possibly tilted up. Facing directly to the camera helps us to analyze your selfie accurately.',
      CV_CODE_307:
        'Image too dark at the face area. Try finding a place with better light to your face. Natural light near windows works well.',
      CV_CODE_308: "There's too much light at your face area. Try a bit further from the light / different spot.",
      CV_CODE_309:
        'The color balance (blue) in your photo is pretty bad / not optimal. Please try taking the photo in different light. Natural light works the best.',
      CV_CODE_310:
        'The color balance (green) in your photo is pretty bad / not optimal. Please try taking the photo in different light. Natural light works the best.',
      CV_CODE_311:
        'The color balance (red) in your photo is pretty bad / not optimal. Please try taking the photo in different light. Natural light works the best',
      CV_CODE_312:
        'The light towards your face is not perfect (uneven across your face) which might decrease the accuracy of the analysis. Try finding a spot with light coming from the direction of your camera.',
      CV_CODE_313:
        'The face area has hard shadows. Try taking the photo so that the main light comes from the direction of the camera.',
      CV_CODE_314: 'We noticed you might be wearing glasses. For better results, take a selfie without glasses.',
      CV_CODE_1000: "You didn't define your skin tone. Please go back and select your skin tone to continue.",
      CV_CODE_1101:
        'Oops. Your eyes seem too closed for us to analyze their color. Please retake your selfie with your eyes open.',
      CV_CODE_1102:
        'Oops, it seems that your eyes may be a bit too closed. For better results, remember to keep your eyes open when taking a selfie.',
      CV_CODE_1111:
        "There seems to be some shadow directed at your eyes. We're not able to analyze your eye color. If you'd like your eye color analyzed, please retake a selfie with better lighting directed at your eyes.",
      CV_CODE_1112:
        'There seems to be some shadow directed at your eyes, making it difficult for us to accurately analyze your eye color. If we were mistaken about your eye color, take a new selfie with better lighting directed at your eyes',
      CV_CODE_1121:
        "Oops, your eye whites don't appear white in the selfie. We're not able to analyze your eye color. If you'd like your eye color analyzed, please retake a selfie with better lighting directed at your eyes.",
      CV_CODE_1122:
        "Oops, your eye whites don't appear white in the selfie, making it difficult for us to analyze your eye color. If we we're mistaken about your eye color, take a new selfie with better lighting directed at your eyes.",
    },
  },
  result: {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    overall: '',
    hyperpigmentation: 'Pigment variation',
    wrinkles: 'Wrinkles',
    redness: 'Redness',
    texture: 'Texture',
    skinShine: 'Skin shine',
    acne: 'Acne',
    smoothness: 'Radiance',
    eyebags: 'Eye puffiness',
    darkcircles: 'Dark circles',
    goToProducts: 'See products',
  },
  recommendedProducts: {
    title: 'Recommendations',
    clearFilters: 'Clear filters',
    filters: 'Filters',
    productType: 'Product Type',
    premium: 'Premium',
    dermatological: 'Dermatological skincare',
    brand: 'Brand',
    No7: 'No7',
    YGS: 'YGS',
    Botanics: 'Botanics',
    Olay: 'Olay',
    LOreal: 'L’Oreal',
    Neutrogena: 'Neutrogena',
    Cerave: 'Cerave',
    Cetaphil: 'Cetaphil',
    LaRochePosay: 'La Roche Posay',
    Vichy: 'Vichy',
    Bioderma: 'Bioderma',
    Avene: 'Avene',
    Walgreens: 'Walgreens',
    gender: 'Gender',
    bestMatch: 'Best match',
    cleanse: '1. Cleanse',
    treat: '2. Treat',
    moisturizeDay: '3. Moisturize - Day',
    moisturizeNight: '4. Moisturize - Night',
    protect: '5. Protect',
  },
  age: {
    title: 'Age',
    _18: '18-24',
    _25: '25-34',
    _35: '35-44',
    _45: '45-54',
    _55: '55+',
  },
};

export default translations;
