import React from 'react';
import { useSelectActions } from '../context';
import { Button } from 'Components/Button';

import * as styled from './styled';

export interface ButtonProps {
  value: string;
}

const SelectorButton: React.FC<ButtonProps> = ({ value, children }) => {
  const { onSelect, onUnselect, selectedValues } = useSelectActions();
  const selected = selectedValues.includes(value);
  const handleClick = () => {
    if (!selected) onSelect(value);
    else onUnselect(value);
  };
  return (
    <styled.button>
      <Button onClick={handleClick} color={selected ? 'secondaryDark' : 'secondary'} size="large" fontSize="small">
        {children}
      </Button>
    </styled.button>
  );
};

export default SelectorButton;
