import React from 'react';
import { useSelectActions } from '../context';
import { Icon } from 'Components/Icon';

import * as styled from './styled';

export interface RadioProps {
  value: string;
  size?: 'small' | 'regular';
}

const SelectorButton: React.FC<RadioProps> = ({ value, children, size = 'regular' }) => {
  const { onSelect, onUnselect, selectedValues } = useSelectActions();
  const selected = selectedValues.includes(value);
  const handleClick = () => {
    if (!selected) onSelect(value);
    else onUnselect(value);
  };
  return (
    <styled.radio onClick={handleClick} size={size}>
      <styled.input size={size}>
        <Icon name={selected ? 'radioOn' : 'radioOff'} />
      </styled.input>
      <styled.text size={size}>{children}</styled.text>
    </styled.radio>
  );
};

export default SelectorButton;
