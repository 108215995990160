import React from 'react';
import { useAction, useGlobalState, navigation, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Title } from 'Components/Title';
import { Actions, Next, Back } from 'Components/Layout/Actions';
import { Select } from 'Components/Select';

import * as styled from './styled';

const SkinType = () => {
  const [t, keys] = useTranslate();
  const age = useGlobalState(({ userInputData }) => userInputData.age);
  const selectAge = useAction(userInputData.selectAge);
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);

  const options = [
    { label: t(keys.age._18), value: '18' },
    { label: t(keys.age._25), value: '25' },
    { label: t(keys.age._35), value: '35' },
    { label: t(keys.age._45), value: '45' },
    { label: t(keys.age._55), value: '55' },
  ];

  return (
    <Page>
      <NavBar />
      <Content>
        <Title step={3}>{t(keys.age.title)}</Title>

        <styled.questionSelector>
          <Select values={[age.toString()]} onSelect={selectAge} layout="normal">
            {options.map(({ label, value }) => (
              <Select.Button key={value} value={value}>
                {label}
              </Select.Button>
            ))}
          </Select>
        </styled.questionSelector>

        <Actions>
          <Back onClick={goBack} />
          <Next onClick={goNext} disabled={age === 0} />
        </Actions>
      </Content>
    </Page>
  );
};

export default SkinType;
