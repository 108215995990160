import React from 'react';

import * as styled from './styled';
import logo from './logo.png';

export const NavBar: React.FC = () => {
  return (
    <styled.navBar>
      <styled.logo>
        <img src={logo} alt="revieve" />
        <h1>Skin Care Advisor</h1>
      </styled.logo>
    </styled.navBar>
  );
};
