import styled from 'styled-components';

export const radio = styled.div<{ size: 'small' | 'regular' }>`
  margin-bottom: ${({ size }) => (size === 'small' ? 5 : 35)}px;
  display: flex;
  align-items: flex-start;
`;

export const checkbox = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 54px 0;
`;

export const input = styled.div<{ size: 'small' | 'regular' }>`
  margin-right: ${({ size }) => (size === 'small' ? 10 : 15)}px;
  height: ${({ size }) => (size === 'small' ? 25 : 40)}px;
  width: ${({ size }) => (size === 'small' ? 25 : 40)}px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
`;

export const text = styled.div<{ size: 'small' | 'regular' }>`
  max-width: 435px;
  padding-top: ${({ size }) => (size === 'small' ? 2 : 7)}px;
  font-size: ${({ size }) => (size === 'small' ? 16 : 28)}px;
  line-height: ${({ size }) => (size === 'small' ? 24 : 34)}px;
`;
