import styled from 'styled-components';

export const icon = styled.div`
  height: 60px;
  margin: 12px;
  color: ${({ theme }) => theme.colors.neutralDark};
  @media (max-height: 560px) {
    height: 0;
    margin: 15px 0 4px;
  }
`;

export const weather = styled.div`
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  width: 100%;
  margin: auto;
`;
