import styled, { DefaultTheme, css } from 'styled-components';

const layouts = {
  compact: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 16px;
  `,
  normal: css`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  `,
  full: css`
    display: grid;
    grid-template-columns: auto;
  `,
};

export type Layout = 'compact' | 'normal' | 'full';

export const select = styled.div<{ theme: DefaultTheme; layout?: Layout }>`
  ${({ layout = 'normal' }) => layouts[layout]}
  padding-bottom: 6px;
`;
