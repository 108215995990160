import styled from 'styled-components';

export const image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
`;

export const contentWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 30px;
`;

export const link = styled.div`
  color: #ffffff;
  font-size: 32px;
  line-height: 80px;
`;

export const message = styled.div`
  padding: 9px;
`;

export const rowActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  max-width: 600px;
  padding: 15px;
`;
