/* eslint-disable @typescript-eslint/no-explicit-any */
import loadGA from './loadGA';
import TestModeAPI from './testModeAPI';

export const testModeAPI = TestModeAPI;

export interface Tracker {
  provider: 'GA';
  name?: string;
  id: string;
  prefix?: string;
  contentGroup?: {
    index?: number;
    name?: string;
  };
  customCategory?: string;
  customAction?: string;
  customLabel?: string;
  enableSystemEvents?: boolean;
  avoidSettingUserId?: boolean;
}

type SessionControlFlag = 'start' | 'end';

const config: {
  enabled: boolean;
  debug: boolean;
  testMode: boolean;
  trackers: Tracker[];
} = {
  enabled: true,
  debug: false,
  testMode: false,
  trackers: [],
};
export const resetTrackers = () => {
  config.trackers = [];
};

const log = (message: string) => {
  console.info('[ga]', message);
};

const isTrackerInitialized = (trackerId: string) =>
  config.trackers.filter(tracker => tracker.id === trackerId).length > 0;
const getTrackerName = ({ trackerId, name }: { trackerId: string; name?: string }) =>
  name ? name : trackerId.replace(/\W/g, '');

const internalGA = (...args: any[]) => {
  if (config.testMode) return TestModeAPI.ga(...args);
  if (!(window as any).ga) return console.warn('[ga]', 'GoogleAnalytics must be initialized first');
  return (window as any).ga(...args);
};

const ga = (...args: any[]) => {
  if (args.length > 0) {
    internalGA(...args);
    if (config.debug) {
      log(`called with args: ${JSON.stringify(args)}`);
    }
  }

  return (window as any).ga;
};

export const enable = () => {
  config.enabled = true;
};

export const disable = () => {
  config.enabled = false;
};

export const initialize = ({ debug = false, testMode = false }: { debug?: boolean; testMode?: boolean }) => {
  if (!config.enabled) return;
  config.debug = debug;
  config.testMode = testMode;
  if (!config.testMode && !(window as any).ga) loadGA();
};

export const addTrackers = ({
  trackers = [],
  userId,
  partnerId,
}: {
  trackers?: Tracker[];
  userId: string;
  partnerId?: string;
}) => {
  if (!config.enabled) return;
  const newTrackerIds = trackers.map(tracker => tracker.id);
  const newGATrackers = trackers
    .filter(({ provider }) => provider === 'GA')
    .filter(({ id }, index) => !isTrackerInitialized(id) && newTrackerIds.indexOf(id) === index);

  newGATrackers.forEach(tracker => {
    const gaOptions = {
      name: getTrackerName({ trackerId: tracker.id, name: tracker.name }),
      userId: tracker.avoidSettingUserId ? undefined : userId,
    };
    ga('create', tracker.id, gaOptions);
  });

  const newInitializedTrackers = newGATrackers.map(tracker => ({
    ...tracker,
    name: getTrackerName({ trackerId: tracker.id, name: tracker.name }),
  }));
  config.trackers = [...config.trackers, ...newInitializedTrackers];

  newInitializedTrackers.forEach(({ contentGroup, name: trackerName }) => {
    if (contentGroup && contentGroup.index && contentGroup.name) {
      ga(`${trackerName}.set`, { [`contentGroup${contentGroup.index}`]: contentGroup.name });
    }
    if (partnerId) {
      ga(`${trackerName}.set`, { dimension1: partnerId });
    }
  });
};
export interface Pageview {
  path: string;
  sessionControl?: 'start' | 'end';
  isSystemEvent?: boolean;
}

export const pageview = ({ path, sessionControl, isSystemEvent = false }: Pageview) => {
  if (!config.enabled) return;

  config.trackers
    .filter(({ enableSystemEvents }) => (isSystemEvent ? enableSystemEvents : true))
    .forEach(({ prefix, name: trackerName }) => {
      const prefixedPath = prefix ? `/${prefix}${path}` : path;
      ga(`${trackerName}.send`, {
        hitType: 'pageview',
        page: prefixedPath,
        ...(sessionControl ? { sessionControl } : {}),
      });
      if (config.debug) {
        log(`sent pageview: ${prefixedPath}`);
      }
    });
};

export interface Event {
  category: string;
  action: string;
  label?: string;
}

const replacePlaceholders = (value: string, { placeholders = {} }: { placeholders: { [key: string]: string } }) => {
  const regExp = new RegExp(`%(${Object.keys(placeholders).join('|')})%`, 'g');
  return value.replace(regExp, (_, key: string) => placeholders[key]);
};

export const sendEvent = (event: Event) => {
  if (!config.enabled) return;
  const isSystemEvent = event.category === 'System';
  config.trackers
    .filter(({ enableSystemEvents }) => (isSystemEvent ? enableSystemEvents : true))
    .forEach(
      ({
        prefix,
        name: trackerName,
        customCategory = '%CATEGORY%',
        customAction = '%ACTION%',
        customLabel = '%LABEL%',
      }) => {
        const prefixedEventCategory = prefix ? `${prefix} ${event.category}` : event.category;
        const placeholders = {
          CATEGORY: prefixedEventCategory,
          ACTION: event.action,
          LABEL: event.label || '',
        };
        const trackerEvent = {
          category: replacePlaceholders(customCategory, { placeholders }),
          action: replacePlaceholders(customAction, { placeholders }),
          label: replacePlaceholders(customLabel, { placeholders }),
        };

        ga(`${trackerName}.send`, {
          hitType: 'event',
          eventCategory: trackerEvent.category,
          eventAction: trackerEvent.action,
          ...(trackerEvent.label ? { eventLabel: trackerEvent.label } : {}),
        });
      },
    );
};

type ActionGeneric<Type, Payload = undefined> = { type: Type } & (Payload extends undefined
  ? {}
  : { payload: Payload });

export type Action = ActionGeneric<'pageChange', { name: string }> | ActionGeneric<'showProducts', { id: string }[]>;

export const dispatch = (action: Action) => {
  switch (action.type) {
    case 'pageChange':
      pageview({ path: `/${action.payload.name}` });
      break;

    case 'showProducts':
      action.payload.forEach(({ id }) => {
        sendEvent({
          category: 'Product recommended',
          action: id,
        });
      });
      break;

    default:
      break;
  }
};
