import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const container = styled.div`
  margin-top: 45px;
  width: 350px;
  width: 100%;
`;

export const title = styled.div`
  ${heavyFont};
  margin-top: 6px;
  font-size: 32px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.primaryDark};
  text-align: center;
`;
