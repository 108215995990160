import styled, { DefaultTheme, css } from 'styled-components';

const selectedCSS = css`
  box-shadow: ${({ theme }) => `0 0 0 6px white, 0 0 0 10px ${theme.colors.pinkDark}`};
`;

const noSelectedCSS = css<{ theme: DefaultTheme; selected: boolean }>`
  border-color: transparent;
`;

export const wrapper = styled('div')``;

export const image = styled.img<{ selected: boolean }>`
  width: 100px;
  height: 100px;
  margin-right: 25px;
  cursor: pointer;
  border-radius: 50%;
  ${({ selected }) => (selected ? selectedCSS : noSelectedCSS)};
`;
