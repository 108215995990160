import { useRef, useState, useEffect, useCallback } from 'react';
import { getRevieveSDK } from './initialization';
import { FindingName } from './types';

export const useRevieveResults = (): [
  React.RefObject<HTMLDivElement>,
  {
    style: { [key: string]: string };
    highlightFinding: (findingName: FindingName) => void;
    deHighlightFinding: () => Promise<void>;
  },
] => {
  const resultsElement = useRef<HTMLDivElement>(null);
  const [selectedFinding, setSelectedFinding] = useState<FindingName | null>(null);
  const [style, setStyle] = useState<{ [key: string]: string }>({});
  const [styleOut, setStyleOut] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (resultsElement.current) {
      // FIXME: Fix SDK. This hack is needed to show the image after initialize.
      resultsElement.current.style.cssText = `
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
      `;
      getRevieveSDK().AR.setContainer(resultsElement.current);
      getRevieveSDK().AR.reset();
      getRevieveSDK()
        .AR.reduceEyebags(0)
        .catch(() => {
          return null;
        });
    }
  }, []);

  const deHighlightFinding = useCallback(async () => {
    await getRevieveSDK().AR.dehighlightAll();
    setStyle(styleOut);
  }, [styleOut]);

  const highlightFinding = useCallback(
    async (findingName: FindingName) => {
      if (selectedFinding === findingName) return;
      getRevieveSDK().AR.dehighlightAll();

      switch (findingName) {
        case 'overall':
          deHighlightFinding();
          break;

        case 'wrinkles':
          if (selectedFinding === 'wrinkles') return;
          await getRevieveSDK().AR.highlightWrinklesIndividually();
          setStyle(styleOut);
          break;

        case 'acne':
          if (selectedFinding === 'acne') return;
          await getRevieveSDK().AR.highlightAcneIndividually();
          setStyle(styleOut);
          break;

        case 'hyperpigmentation':
          if (selectedFinding === 'hyperpigmentation') return;
          await getRevieveSDK().AR.highlightHyperpigmentationIndividually();
          setStyle(styleOut);
          break;

        case 'skinShine':
          await getRevieveSDK().AR.highlightFinding('skinShine');
          setStyle(styleOut);
          break;

        default: {
          const effectStyles = await getRevieveSDK().AR.highlightFinding(findingName);
          setStyle(effectStyles.in);
          setStyleOut(effectStyles.out);
          break;
        }
      }

      setSelectedFinding(findingName);
    },
    [styleOut, selectedFinding, deHighlightFinding],
  );

  return [resultsElement, { style, highlightFinding, deHighlightFinding }];
};
