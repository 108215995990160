import React from 'react';
import { useGlobalState, useAction, navigation, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Actions, Next, Back } from 'Components/Layout/Actions';
import { Select } from 'Components/Select';

import eyebags1 from './eyebags/option_01.jpg';
import eyebags2 from './eyebags/option_02.jpg';
import eyebags3 from './eyebags/option_03.jpg';
import darkspots1 from './darkspots/option_01.jpg';
import darkspots2 from './darkspots/option_02.jpg';
import darkspots3 from './darkspots/option_03.jpg';
import wrinkles1 from './wrinkles/option_01.jpg';
import wrinkles2 from './wrinkles/option_02.jpg';
import wrinkles3 from './wrinkles/option_03.jpg';
import wrinkles4 from './wrinkles/option_04.jpg';

import * as styled from './styled';

export const Questions = () => {
  const [t, keys] = useTranslate();
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);
  const eyebags = useGlobalState(({ userInputData }) => userInputData.eyebags);
  const darkSpots = useGlobalState(({ userInputData }) => userInputData.darkSpots);
  const wrinkles = useGlobalState(({ userInputData }) => userInputData.wrinkles);
  const selectEyebags = useAction(userInputData.selectEyebags);
  const selectDarkSpots = useAction(userInputData.selectDarkSpots);
  const selectWrinkles = useAction(userInputData.selectWrinkles);

  return (
    <Page>
      <NavBar />
      <Content>
        <styled.title>{t(keys.selfie.question.title)}</styled.title>
        <styled.content>
          <styled.finding>{t(keys.selfie.question.eyebags)}</styled.finding>
          <Select values={[eyebags?.toString()]} onSelect={value => selectEyebags(parseFloat(value))} layout="compact">
            <Select.Image image={eyebags1} value="0" />
            <Select.Image image={eyebags2} value="0.5" />
            <Select.Image image={eyebags3} value="1" />
          </Select>
          <styled.finding>{t(keys.selfie.question.darkSpots)}</styled.finding>
          <Select
            values={[darkSpots?.toString()]}
            onSelect={value => selectDarkSpots(parseFloat(value))}
            layout="compact"
          >
            <Select.Image image={darkspots1} value="0" />
            <Select.Image image={darkspots2} value="0.5" />
            <Select.Image image={darkspots3} value="1" />
          </Select>
          <styled.finding>{t(keys.selfie.question.wrinkles)}</styled.finding>
          <Select
            values={[wrinkles?.toString()]}
            onSelect={value => selectWrinkles(parseFloat(value))}
            layout="compact"
          >
            <Select.Image image={wrinkles1} value="0" />
            <Select.Image image={wrinkles2} value="0.33" />
            <Select.Image image={wrinkles3} value="0.66" />
            <Select.Image image={wrinkles4} value="1" />
          </Select>
        </styled.content>
        <Actions>
          <Back onClick={goBack} />
          <Next
            onClick={goNext}
            disabled={eyebags === undefined || darkSpots === undefined || wrinkles === undefined}
          />
        </Actions>
      </Content>
    </Page>
  );
};
