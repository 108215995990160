import styled from 'styled-components';

export const wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 1px 6px 1px rgba(64, 64, 64, 0.15);
  padding: 6px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 0 5px 7px;
`;

export const close = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: solid 1px #404040;
  color: #404040;
  padding: 0 5px;
`;

export const label = styled.div`
  font-size: 16px;
  line-height: 18px;
  padding-top: 2px;
  margin: 0 5px;
`;
