import styled, { DefaultTheme } from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const navBar = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  height: 100px;
  background-color: #02a0c0;
  h1 {
    ${heavyFont}
    color: white;
    font-size: 34px;
  }
`;

export const logo = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: 60px;
  }
`;

export const icon = styled('div')<{ theme: DefaultTheme }>`
  color: ${({ theme }) => theme.colors.neutralDark};
  cursor: pointer;
  min-width: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
`;
