import React from 'react';
import { Dots } from './Dots';
import * as styled from './styled';

export const Title: React.FC<{
  step?: number;
}> = ({ children, step = 0 }) => (
  <styled.wrapper>
    <styled.container>
      <Dots total={7} selected={step} />
      <styled.title>{children}</styled.title>
    </styled.container>
  </styled.wrapper>
);
