export const keys = {
  language: 'language',
  common: {
    next: 'common.next',
    back: 'common.back',
  },
  landing: {
    intro: 'landing.intro',
    detail1: 'landing.detail1',
    detail2: 'landing.detail2',
    detail3: 'landing.detail3',
    start: 'landing.start',
    termns: {
      link: 'landing.termns.link',
      title: 'landing.termns.title',
      text: 'landing.termns.text',
    },
  },
  gender: {
    title: 'gender.title',
    male: 'gender.male',
    female: 'gender.female',
  },
  skinType: {
    title: 'skinType.title',
    options: {
      sensitive: {
        name: 'skinType.options.sensitive.name',
      },
      dry: {
        name: 'skinType.options.dry.name',
      },
      oily: {
        name: 'skinType.options.oily.name',
      },
      combination: {
        name: 'skinType.options.combination.name',
      },
      normal: {
        name: 'skinType.options.normal.name',
      },
      unknown: {
        name: 'skinType.options.unknown.name',
      },
    },
    questions: {
      skinFeel: {
        text: 'skinType.questions.skinFeel.text',
        options: {
          dry: 'skinType.questions.skinFeel.options.dry',
          oily: 'skinType.questions.skinFeel.options.oily',
          normal: 'skinType.questions.skinFeel.options.normal',
          combination: 'skinType.questions.skinFeel.options.combination',
        },
      },
      pores: {
        text: 'skinType.questions.pores.text',
        options: {
          dry: 'skinType.questions.pores.options.dry',
          oily: 'skinType.questions.pores.options.oily',
          normal: 'skinType.questions.pores.options.normal',
          sensitive: 'skinType.questions.pores.options.sensitive',
          combination: 'skinType.questions.pores.options.combination',
        },
      },
      afternoonFeel: {
        text: 'skinType.questions.afternoonFeel.text',
        options: {
          dry: 'skinType.questions.afternoonFeel.options.dry',
          oily: 'skinType.questions.afternoonFeel.options.oily',
          normal: 'skinType.questions.afternoonFeel.options.normal',
          combination: 'skinType.questions.afternoonFeel.options.combination',
        },
      },
      acne: {
        text: 'skinType.questions.acne.text',
        options: {
          dry: 'skinType.questions.acne.options.dry',
          oily: 'skinType.questions.acne.options.oily',
          normal: 'skinType.questions.acne.options.normal',
          combination: 'skinType.questions.acne.options.combination',
          sensitive: 'skinType.questions.acne.options.sensitive',
        },
      },
      morningFeel: {
        text: 'skinType.questions.morningFeel.text',
        options: {
          dry: 'skinType.questions.morningFeel.options.dry',
          oily: 'skinType.questions.morningFeel.options.oily',
          normal: 'skinType.questions.morningFeel.options.normal',
          combination: 'skinType.questions.morningFeel.options.combination',
        },
      },
    },
    result: {
      text: 'skinType.result.text',
    },
  },
  skinConcerns: {
    title: 'skinConcerns.title',
    question: 'skinConcerns.question',
    wrinkles: 'skinConcerns.wrinkles',
    eyebags: 'skinConcerns.eyebags',
    redness: 'skinConcerns.redness',
    dull: 'skinConcerns.dull',
    aging: 'skinConcerns.aging',
    acne: 'skinConcerns.acne',
    unevenTone: 'skinConcerns.unevenTone',
    pores: 'skinConcerns.pores',
    lossOfFirmess: 'skinConcerns.lossOfFirmess',
  },
  location: {
    title: 'location.title',
    weather: {
      placeholder: 'location.weather.placeholder',
      uvIndex: 'location.weather.uvIndex',
      humidity: 'location.weather.humidity',
      pollution: 'location.weather.pollution',
    },
  },
  age: {
    title: 'age.title',
    _18: 'age._18',
    _25: 'age._25',
    _35: 'age._35',
    _45: 'age._45',
    _55: 'age._55',
  },
  selfieInstructions: {
    title: 'selfieInstructions.title',
    text: 'selfieInstructions.text',
    takeSelfie: 'selfieInstructions.takeSelfie',
    skipSelfie: 'selfieInstructions.skipSelfie',
    removeGlasses: 'selfieInstructions.removeGlasses',
    pullBackHair: 'selfieInstructions.pullBackHair',
    removeMakeup: 'selfieInstructions.removeMakeup',
    goodLight: 'selfieInstructions.goodLight',
    lookAtCamera: 'selfieInstructions.lookAtCamera',
    neutralExpresion: 'selfieInstructions.neutralExpresion',
  },
  selfie: {
    question: {
      title: 'selfie.question.title',
      eyebags: 'selfie.question.eyebags',
      darkSpots: 'selfie.question.darkSpots',
      wrinkles: 'selfie.question.wrinkles',
    },
    takeSelfie: {
      cancel: 'selfie.takeSelfie.cancel',
    },
    error: {
      retry: 'selfie.error.retry',
    },
    analyzing: {
      text: 'selfie.analyzing.text',
    },
    messages: {
      CV_CODE_NO_CAMERA: 'messages.NO_CAMERA',
      CV_CODE_100: 'messages.CV_CODE_100',
      CV_CODE_101: 'messages.CV_CODE_101',
      CV_CODE_102: 'messages.CV_CODE_102',
      CV_CODE_103: 'messages.CV_CODE_103',
      CV_CODE_104: 'messages.CV_CODE_104',
      CV_CODE_201: 'messages.CV_CODE_201',
      CV_CODE_202: 'messages.CV_CODE_202',
      CV_CODE_203: 'messages.CV_CODE_203',
      CV_CODE_204: 'messages.CV_CODE_204',
      CV_CODE_205: 'messages.CV_CODE_205',
      CV_CODE_301: 'messages.CV_CODE_301',
      CV_CODE_302: 'messages.CV_CODE_302',
      CV_CODE_303: 'messages.CV_CODE_303',
      CV_CODE_304: 'messages.CV_CODE_304',
      CV_CODE_305: 'messages.CV_CODE_305',
      CV_CODE_306: 'messages.CV_CODE_306',
      CV_CODE_307: 'messages.CV_CODE_307',
      CV_CODE_308: 'messages.CV_CODE_308',
      CV_CODE_309: 'messages.CV_CODE_309',
      CV_CODE_310: 'messages.CV_CODE_310',
      CV_CODE_311: 'messages.CV_CODE_311',
      CV_CODE_312: 'messages.CV_CODE_312',
      CV_CODE_313: 'messages.CV_CODE_313',
      CV_CODE_314: 'messages.CV_CODE_314',
      CV_CODE_1000: 'messages.CV_CODE_1000',
      CV_CODE_1101: 'messages.CV_CODE_1101',
      CV_CODE_1102: 'messages.CV_CODE_1102',
      CV_CODE_1111: 'messages.CV_CODE_1111',
      CV_CODE_1112: 'messages.CV_CODE_1112',
      CV_CODE_1121: 'messages.CV_CODE_1121',
      CV_CODE_1122: 'messages.CV_CODE_1122',
    },
  },
  result: {
    low: 'result.low',
    medium: 'result.medium',
    high: 'result.high',
    overall: 'result.overall',
    hyperpigmentation: 'result.hyperpigmentation',
    wrinkles: 'result.wrinkles',
    redness: 'result.redness',
    texture: 'result.texture',
    skinShine: 'result.skinShine',
    acne: 'result.acne',
    smoothness: 'result.smoothness',
    eyebags: 'result.eyebags',
    darkcircles: 'result.darkcircles',
    goToProducts: 'result.goToProducts',
  },
  recommendedProducts: {
    title: 'recommendedProducts.title',
    clearFilters: 'recommendedProducts.clearFilters',
    bestMatch: 'recommendedProducts.bestMatch',
    filters: 'recommendedProducts.filters',
    productType: 'recommendedProducts.productType',
    premium: 'recommendedProducts.premium',
    dermatological: 'recommendedProducts.dermatological',
    brand: 'recommendedProducts.brand',
    No7: 'recommendedProducts.No7',
    YGS: 'recommendedProducts.YGS',
    Botanics: 'recommendedProducts.Botanics',
    Olay: 'recommendedProducts.Olay',
    LOreal: 'recommendedProducts.LOreal',
    Neutrogena: 'recommendedProducts.Neutrogena',
    Cerave: 'recommendedProducts.Cerave',
    Cetaphil: 'recommendedProducts.Cetaphil',
    LaRochePosay: 'recommendedProducts.LaRochePosay',
    Vichy: 'recommendedProducts.Vichy',
    Bioderma: 'recommendedProducts.Bioderma',
    Avene: 'recommendedProducts.Avene',
    Walgreens: 'recommendedProducts.Walgreens',
    gender: 'recommendedProducts.gender',
    cleanse: 'recommendedProducts.cleanse',
    treat: 'recommendedProducts.treat',
    moisturizeDay: 'recommendedProducts.moisturizeDay',
    moisturizeNight: 'recommendedProducts.moisturizeNight',
    protect: 'recommendedProducts.protect',
  },
};
export type Translations = typeof keys;
