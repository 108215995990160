import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';
export const wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  flex-shrink: 0;
  flex-basis: auto;
`;

export const title = styled.div`
  ${heavyFont}
  color: #000000;
  flex: 1;
  width: 100%;
  padding: 15px;
  text-align: left;
`;
