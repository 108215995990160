import React, { useRef, useEffect, useState } from 'react';
import { SnapList, SnapItem } from 'react-snaplist-carousel';
import { Metric as MetricType } from './Result';
import { Metric } from './Metric';

export const Metrics: React.FC<{ metrics: MetricType[]; onSelect: (metric: MetricType) => void }> = ({
  metrics,
  onSelect,
}) => {
  const snapList = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    onSelect(metrics[selected]);
  }, [selected, metrics, onSelect]);

  return (
    <SnapList ref={snapList}>
      <>
        {metrics.map(({ name, value }, index) => {
          const isFirst = index === 0;
          const isLast = index === metrics.length - 1;
          return (
            <SnapItem
              key={name}
              paddingLeft={isFirst ? '15px' : '3px'}
              paddingRight={isLast ? '15px' : '3px'}
              snapAlign="center"
            >
              <Metric onSelect={() => setSelected(index)} selected={selected === index} name={name} value={value} />
            </SnapItem>
          );
        })}
      </>
    </SnapList>
  );
};
