import React from 'react';
import { useTranslate } from 'Locale';
import { Button } from 'Components/Button';
import { Icon } from 'Components/Icon';

import * as styled from './styled';

export const Actions: React.FC = ({ children }) => <styled.wrapper>{children}</styled.wrapper>;

export const Next: React.FC<{
  onClick: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled }) => {
  const [t, keys] = useTranslate();
  return (
    <Button color="primary" size="large" onClick={onClick} disabled={disabled}>
      {t(keys.common.next)}
    </Button>
  );
};

export const Back: React.FC<{
  onClick: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled }) => {
  const [t, keys] = useTranslate();
  return (
    <Button color="ghost" onClick={onClick} disabled={disabled}>
      <styled.icon>
        <Icon name="back" />
      </styled.icon>
      {t(keys.common.back)}
    </Button>
  );
};
