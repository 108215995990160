import React from 'react';

import * as styled from './styled';

export interface ButtonProps {
  onClick: () => void;
  color: 'neutral' | 'primary' | 'secondary' | 'secondaryDark' | 'ghost' | 'light';
  disabled?: boolean;
  size?: 'normal' | 'large';
  fontSize?: 'normal' | 'small';
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  color,
  disabled = false,
  size = 'normal',
  fontSize = 'normal',
}) => (
  <styled.button
    onClick={disabled ? undefined : () => onClick()}
    color={color}
    disabled={disabled}
    size={size}
    fontSize={fontSize}
  >
    {children}
  </styled.button>
);
