import React from 'react';
import { useSelectActions } from '../context';
import { Icon } from 'Components/Icon';

import * as styled from './styled';

export interface CheckProps {
  value: string;
  size?: 'small' | 'regular';
  disabled?: boolean;
}

const SelectorCheck: React.FC<CheckProps> = ({ value, children, size = 'regular', disabled = false }) => {
  const { onSelect, onUnselect, selectedValues } = useSelectActions();
  const selected = selectedValues.includes(value);
  const handleClick = () => {
    if (disabled) return;
    if (!selected) onSelect(value);
    else onUnselect(value);
  };
  return (
    <styled.radio onClick={handleClick} size={size} disabled={disabled}>
      <styled.input size={size} disabled={disabled}>
        <Icon name={selected ? 'checkOn' : 'checkOff'} />
      </styled.input>
      <styled.text size={size}>{children}</styled.text>
    </styled.radio>
  );
};

export default SelectorCheck;
