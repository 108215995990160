import React, { useState } from 'react';
import { useAction, useGlobalState, navigation, State, userInputData } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { SkinTypeSelector } from './SkinTypeSelector';
import { Question } from './Question';
import { QuestionResult } from './QuestionResult';

type Step = 'skinTypeSelector' | 'skinFeel' | 'pores' | 'afternoonFeel' | 'acne' | 'morningFeel' | 'result';
const steps: Step[] = ['skinTypeSelector', 'skinFeel', 'pores', 'afternoonFeel', 'acne', 'morningFeel', 'result'];
export type Answers = Record<Step, SkinType>;
type OptionsSkinType = State['userInputData']['skinType'];
export type SkinType = Required<State['userInputData']>['skinType'];

const SkinTypeContent: React.FC<{
  step: Step;
  skinType?: SkinType;
  onSelect: (skinType: SkinType) => void;
  onFinish: () => void;
  onNextStep: () => void;
  onBackStep: () => void;
}> = ({ step, onNextStep, onBackStep, onSelect, onFinish, skinType }) => {
  const [t, keys] = useTranslate();
  const [answers, setAnswers] = useState<Answers>({} as Answers);
  const handleAnswer = (step: Step) => (value: string) => setAnswers({ ...answers, [step]: value });

  const handleOnSelect = (value: string) => onSelect(value as SkinType);

  switch (step) {
    case 'skinTypeSelector':
      return (
        <SkinTypeSelector
          skinType={skinType}
          onStartQuestions={onNextStep}
          onFinish={onFinish}
          onSelect={handleOnSelect}
        />
      );
    case 'result':
      return <QuestionResult onNextStep={onFinish} onBackStep={onBackStep} answers={answers} onSelect={onSelect} />;

    default: {
      const options = Object.entries(keys.skinType.questions[step].options).map(([skinTypeOption, translationKey]) => ({
        label: t(translationKey),
        value: skinTypeOption as SkinType,
      }));

      return (
        <Question
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          text={t(keys.skinType.questions[step].text)}
          options={options}
          answer={answers[step]}
          onAnswer={handleAnswer(step)}
        />
      );
    }
  }
};

const SkinType = () => {
  const skinType = useGlobalState(({ userInputData }) => userInputData.skinType);
  const goNext = useAction(navigation.goNext);
  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const selectSkinType = useAction(userInputData.selectSkinType);

  const handleNext = () => {
    const nexIndex = steps.indexOf(currentStep) + 1;
    setCurrentStep(steps[nexIndex]);
  };

  const handleBack = () => {
    const nexIndex = steps.indexOf(currentStep) - 1;
    setCurrentStep(steps[nexIndex]);
  };

  return (
    <Page>
      <NavBar />
      <Content>
        <SkinTypeContent
          step={currentStep}
          skinType={skinType}
          onSelect={selectSkinType}
          onFinish={goNext}
          onNextStep={handleNext}
          onBackStep={handleBack}
        />
      </Content>
    </Page>
  );
};

export default SkinType;
