import React from 'react';
import { Select } from 'Components/Select';
import { Actions, Back, Next } from 'Components/Layout/Actions';

import * as styled from './styled';

export const Question: React.FC<{
  onNextStep: () => void;
  onBackStep: () => void;
  text: string;
  options?: {
    label: string;
    value: string;
  }[];
  answer?: string;
  onAnswer?: (value: string) => void;
}> = ({ onNextStep, onBackStep, text, options, answer, onAnswer }) => {
  const handleSelect = (value: string) => {
    onAnswer && onAnswer(value);
  };

  return (
    <>
      <styled.questionWrapper>
        <styled.question>{text}</styled.question>
        <styled.questionSelector>
          {options && (
            <Select values={[answer]} onSelect={handleSelect} layout="full">
              {options.map(({ label, value }) => (
                <Select.Radio key={value} value={value}>
                  {label}
                </Select.Radio>
              ))}
            </Select>
          )}
        </styled.questionSelector>
      </styled.questionWrapper>
      <Actions>
        <Back onClick={onBackStep} />
        <Next onClick={onNextStep} disabled={answer === undefined} />
      </Actions>
    </>
  );
};
