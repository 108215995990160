import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import * as navigation from './navigation';
import * as userInputData from './userInputData';
import * as selfie from './selfie';
import * as recommendedProducts from './recommendedProducts';

const rootReducer = combineReducers({
  navigation: persistReducer({ key: `navigation`, storage }, navigation.reducer),
  userInputData: persistReducer({ key: `userInputData`, storage }, userInputData.reducer),
  selfie: persistReducer({ key: `selfie`, storage }, selfie.reducer),
  recommendedProducts: persistReducer({ key: `recommendedProducts`, storage }, recommendedProducts.reducer),
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
