import RevieveSDK, { CVFindings as CVFindingsInterface } from '@revieve/sdk';
import { getRevieveSDK } from './initialization';
import { Findings } from './types';

interface Warning {
  code: number;
  description: string;
}

export interface AnalyseImageResult {
  warnings: Warning[];
  hasErrors: boolean;
  findings?: Findings;
}

const mapFindings = (CVFindings: CVFindingsInterface): Findings => ({
  overall: 0,
  hyperpigmentation: CVFindings['hyperpigmentation'].value,
  wrinkles: CVFindings['wrinkles'].value,
  redness: CVFindings['redness'].value,
  texture: CVFindings['texture'].value,
  skinShine: CVFindings['skinShine'].value,
  acne: CVFindings['acne'].value,
  smoothness: CVFindings['smoothness'].value,
  eyebags: CVFindings['eyebags'].value,
  darkcircles: CVFindings['darkcircles'].value,
});

const mapStatus = (
  status: {
    idx: number;
    description: string;
  }[],
) =>
  Object.values(status).map(({ idx, description }) => ({
    code: idx,
    description,
  }));

export const analyseImage = async ({ image, gender }: { image: string; gender?: string }) => {
  try {
    getRevieveSDK().setConfiguration({
      components: [
        'eyes',
        'wrinkles',
        'redness',
        'hyperpigmentation',
        'texture',
        'smoothness',
        'makeup',
        'masks',
        'wrinkles_visualization',
        'hyperpigmentation_visualization',
        'shine',
        'shine_visualization',
        'acne',
        'acne_visualization',
      ],
    });
    getRevieveSDK().setGender(
      gender ? RevieveSDK.gender[gender.toUpperCase()] || RevieveSDK.gender.FEMALE : RevieveSDK.gender.FEMALE,
    );
    await getRevieveSDK().CV.setImage(image);
    await getRevieveSDK().CV.analyzeImage();
    const findings = mapFindings(getRevieveSDK().CV.getFindings(true));
    return {
      warnings: mapStatus(getRevieveSDK().CV.getStatus()),
      hasErrors: getRevieveSDK().CV.hasError(),
      findings,
    };
  } catch (error) {
    console.log(error);
    return {
      warnings: mapStatus(getRevieveSDK().CV.getStatus()),
      hasErrors: true,
    };
  }
};
