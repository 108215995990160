import React from 'react';
import { Icon } from 'Components/Icon';

import * as styled from './styled';

export const Chip: React.FC<{ onClick: () => void }> = ({ children, onClick }) => (
  <styled.wrapper>
    <styled.close onClick={onClick}>
      <Icon name="close" />
    </styled.close>
    <styled.label>{children}</styled.label>
  </styled.wrapper>
);
