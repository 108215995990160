import React from 'react';
import { useTranslate } from 'Locale';
import { SkinType } from './SkinType';
import { useAction, navigation } from 'State';
import { Select } from 'Components/Select';
import { Title } from 'Components/Title';
import { Actions, Next, Back } from 'Components/Layout/Actions';

import * as styled from './styled';

export const SkinTypeSelector: React.FC<{
  onStartQuestions: () => void;
  onFinish: () => void;
  onSelect: (skinType: string) => void;
  skinType?: SkinType;
}> = ({ onStartQuestions, onFinish, onSelect, skinType }) => {
  const [t, keys] = useTranslate();
  const goBack = useAction(navigation.goBack);

  const options = [
    { label: t(keys.skinType.options.dry.name), value: 'dry' },
    { label: t(keys.skinType.options.oily.name), value: 'oily' },
    { label: t(keys.skinType.options.sensitive.name), value: 'sensitive' },
    { label: t(keys.skinType.options.normal.name), value: 'normal' },
    { label: t(keys.skinType.options.combination.name), value: 'combination' },
    { label: t(keys.skinType.options.unknown.name), value: 'unknown' },
  ];

  return (
    <>
      <Title step={0}>{t(keys.skinType.title)}</Title>

      <styled.options>
        <Select values={[skinType]} onSelect={onSelect} layout="normal">
          {options.map(({ label, value }) => (
            <Select.Button key={value} value={value}>
              {label}
            </Select.Button>
          ))}
        </Select>
      </styled.options>

      <Actions>
        <Back onClick={goBack} />
        <Next onClick={skinType === 'unknown' ? onStartQuestions : onFinish} disabled={skinType === undefined} />
      </Actions>
    </>
  );
};
