import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';
export const wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: content-box;
  * {
    box-sizing: content-box;
  }
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 0.6);
  margin: 5px;
  text-align: left;
  min-height: 320px;
`;

export const image = styled.img`
  max-width: 100%;
  max-height: 190px;
`;

export const manufacturer = styled.div`
  margin-top: 30px;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
`;

export const name = styled.div`
  width: 100%;
  ${heavyFont}
  color: #000000;
  height: 2em;
  font-size: 16px;
  line-height: 21px;
  margin-top: 6px;
`;

export const bestMatch = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f0668a;
  ${heavyFont}
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 0 4px;
`;
