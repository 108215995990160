import React from 'react';

import * as styled from './styled';

export const Dots: React.FC<{
  total: number;
  selected: number;
}> = ({ total, selected }) => (
  <styled.wrapper>
    {new Array(total).fill('').map((_value, index) => (
      <React.Fragment key={index}>
        <styled.dot selected={index === selected} done={index < selected} />
        {index < total - 1 && <styled.separator done={index < selected} key={_value} />}
      </React.Fragment>
    ))}
  </styled.wrapper>
);
