import { useState, useEffect, useMemo } from 'react';

const baseURL = {
  prod: 'https://partner.revieve.com',
  test: 'https://partner-test.revieve.com',
  local: 'https://partner-test.revieve.com',
};

type Environment = keyof typeof baseURL;

interface GeoIpResponse {
  accuracy: number;
  area?: string;
  city?: string;
  country: string;
  lat: number;
  lon: number;
}

const geoIpLocation = async ({
  filters = {},
  environment = 'prod',
}: {
  filters?: object;
  environment?: Environment;
}) => {
  const response = await fetch(`${baseURL[environment]}/api/functions/geoIp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
    body: JSON.stringify(filters),
  });
  const data: { result: GeoIpResponse } = await response.json();
  return data.result;
};

export const useLocation = ({
  environment,
  location = {},
}: {
  environment?: Environment;
  location?: {
    lat?: number;
    lon?: number;
  };
}) => {
  const [status, setStatus] = useState<'loading' | 'error' | 'done'>('loading');
  const [lat, setLat] = useState<number | undefined>(location.lat);
  const [lon, setLon] = useState<number | undefined>(location.lon);

  useEffect(() => {
    if (lat === undefined || lon === undefined) {
      const fetchFromIP = async () => {
        try {
          const { lat, lon } = await geoIpLocation({ environment });
          setLat(lat);
          setLon(lon);
        } catch (error) {
          setStatus('error');
        }
      };
      fetchFromIP();
    } else {
      setStatus('done');
    }
  }, [lat, lon, environment]);

  const response = useMemo(() => {
    return status === 'done' ? ({ lat, lon } as { lat: number; lon: number }) : { lat: undefined, lon: undefined };
  }, [status, lat, lon]);
  return response;
};
