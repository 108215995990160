import React, { FunctionComponent } from 'react';
import { Icon, IconProps } from 'Components/Icon';

import * as styled from './styled';

const Weather: FunctionComponent = ({ children }) => {
  return <styled.wrapper>{children}</styled.wrapper>;
};

export const WeatherItem: FunctionComponent<{
  value: string;
  label: string;
  icon: IconProps['name'];
  description?: string;
}> = ({ value, label, icon, description }) => {
  return (
    <styled.item>
      <styled.icon>
        <Icon name={icon} />
      </styled.icon>
      <styled.label>{label}</styled.label>
      <styled.value>{value}</styled.value>
      {description && <styled.description>{description}</styled.description>}
    </styled.item>
  );
};

export default Weather;
