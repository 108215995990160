import React, { useEffect, useState } from 'react';
import { useAction, useGlobalState, navigation, recommendedProducts, userInputData } from 'State';
import { Routine as RoutineName } from 'State/recommendedProducts';
import { useTranslate } from 'Locale';
import { mapProductTypeBrands } from 'State/recommendedProducts';
import * as analyticsService from 'Services/analytics';
import { Page, NavBar, FullscreenContent } from 'Components/Layout';
import { Actions, Back } from 'Components/Layout/Actions';
import { Title } from 'Components/Title';
import { Button } from 'Components/Button';
import { Routine } from './Routine';
import { Filters } from './Filters';
import { Chip } from './Chip';

import * as styled from './styled';

export const RecommendedProducts = () => {
  const [t, keys] = useTranslate();
  const [showFilters, setShowFilters] = useState(false);
  const status = useGlobalState(({ recommendedProducts }) => recommendedProducts.status);
  const fetchProducts = useAction(recommendedProducts.fetchProducts);
  const brands = useGlobalState(({ userInputData }) => userInputData.brands);
  const unselectBrand = useAction(userInputData.unselectBrand);
  const productTypes = useGlobalState(({ userInputData }) => userInputData.productTypes);
  const unselectProductType = useAction(userInputData.unselectProductType);
  const goBack = useAction(navigation.goBack);
  const routines = useGlobalState(recommendedProducts.routinesSelector);
  const fetchRecommendedProducts = useAction(recommendedProducts.fetchProducts);
  const reset = useAction(recommendedProducts.reset);

  useEffect(() => {
    fetchRecommendedProducts();
    return () => {
      reset();
    };
  }, [fetchRecommendedProducts, reset]);

  useEffect(() => {
    const products = Object.values(routines).reduce((acc, products) => [...acc, ...products], []);
    analyticsService.dispatch({ type: 'showProducts', payload: products });
  }, [routines]);

  const isLoading = ['onHold', 'fetching'].includes(status);
  const isProductTypeFilterSelected = productTypes.length > 0;
  const enabledBrands = mapProductTypeBrands(productTypes);

  return (
    <>
      <Page>
        <Filters show={showFilters} onHide={() => setShowFilters(false)} />
        <NavBar />
        <FullscreenContent>
          <Title step={6}>{t(keys.recommendedProducts.title)}</Title>
          <styled.content>
            {isLoading ? (
              <styled.loadingWrapper>
                <styled.animation>
                  <div className="loader__wrapper">
                    <div className="loader">&nbsp;</div>
                  </div>
                </styled.animation>
              </styled.loadingWrapper>
            ) : (
              <>
                <styled.filters>
                  <div>
                    <Button color="light" onClick={() => setShowFilters(true)}>
                      {t(keys.recommendedProducts.filters)}
                    </Button>
                  </div>
                  <styled.chips>
                    {productTypes.map(productType => (
                      <Chip
                        key={productType}
                        onClick={() => {
                          unselectProductType(productType);
                          fetchProducts();
                        }}
                      >
                        {t(keys.recommendedProducts[productType])}
                      </Chip>
                    ))}
                    {brands.map(brand =>
                      isProductTypeFilterSelected && !enabledBrands.includes(brand) ? null : (
                        <Chip
                          key={brand}
                          onClick={() => {
                            unselectBrand(brand);
                            fetchProducts();
                          }}
                        >
                          {t(keys.recommendedProducts[brand])}
                        </Chip>
                      ),
                    )}
                  </styled.chips>
                </styled.filters>
                {Object.entries(routines).map(([name, products]) => (
                  <Routine key={name} name={name as RoutineName} products={products} />
                ))}
              </>
            )}
            <styled.actions>
              <Actions>
                <Back onClick={goBack} />
              </Actions>
            </styled.actions>
          </styled.content>
        </FullscreenContent>
      </Page>
    </>
  );
};
