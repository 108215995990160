import React, { useRef } from 'react';
import { useAction, navigation, selfie } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { toBase64 } from 'Utils/files';
import { Button } from 'Components/Button';
import { Title } from 'Components/Title';
import { Actions, Back } from 'Components/Layout/Actions';

import * as styled from './styled';

const SelfieInstructions = () => {
  const [t, keys] = useTranslate();
  const fileInput = useRef<HTMLInputElement>(null);
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);
  const selectSource = useAction(selfie.selectSource);
  const uploadSelfie = useAction(selfie.uploadSelfie);

  const handletakeSelfie = () => {
    selectSource('video');
    goNext();
  };

  const handleSkipSelfie = () => {
    selectSource('questions');
    goNext();
  };

  const handleFileInputChange = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const fileBase64 = await toBase64({ file: files[0] });
      if (fileBase64) {
        uploadSelfie(fileBase64);
        goNext();
      }
    }
  };

  return (
    <>
      <styled.inputFile
        type="file"
        id="fileinput"
        ref={fileInput}
        onChange={e => handleFileInputChange(e.target.files)}
      />
      <Page>
        <NavBar />
        <Content>
          <Title step={5}>{t(keys.selfieInstructions.title)}</Title>
          <styled.instructions>
            <styled.text>{t(keys.selfieInstructions.text)}</styled.text>
            <styled.tips>
              <styled.tip>{t(keys.selfieInstructions.removeGlasses)}</styled.tip>
              <styled.tip>{t(keys.selfieInstructions.pullBackHair)}</styled.tip>
              <styled.tip>{t(keys.selfieInstructions.removeMakeup)}</styled.tip>
              <styled.tip>{t(keys.selfieInstructions.goodLight)}</styled.tip>
              <styled.tip>{t(keys.selfieInstructions.lookAtCamera)}</styled.tip>
              <styled.tip>{t(keys.selfieInstructions.neutralExpresion)}</styled.tip>
            </styled.tips>

            <styled.actions>
              <Button onClick={handleSkipSelfie} size="large" color="light">
                {t(keys.selfieInstructions.skipSelfie)}
              </Button>
              <Button onClick={handletakeSelfie} size="large" color="primary">
                {t(keys.selfieInstructions.takeSelfie)}
              </Button>
            </styled.actions>
          </styled.instructions>
          <Actions>
            <Back onClick={goBack} />
          </Actions>
        </Content>
      </Page>
    </>
  );
};

export default SelfieInstructions;
