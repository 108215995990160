import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const button = styled('div')<{
  color: 'primary' | 'secondary' | 'neutral' | 'secondaryDark' | 'ghost' | 'light';
  disabled: boolean;
  size: 'normal' | 'large';
  fontSize: 'normal' | 'small';
}>`
  ${heavyFont}
  border-radius: 10px;
  padding: ${({ fontSize }) => (fontSize === 'small' ? ' 20px 8px 18px' : '25px 12px 20px')};
  font-size: ${({ fontSize }) => (fontSize === 'small' ? 24 : 28)}px;
  min-width: ${({ size }) => (size === 'large' ? 270 : 100)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color, theme, disabled }) => {
    if (disabled) return '#8a8a8a';
    switch (color) {
      case 'primary':
        return '#ffffff';
      case 'secondary':
        return theme.colors.secondaryDark;
      case 'secondaryDark':
        return '#ffffff';
      case 'ghost':
        return theme.colors.primaryDark;
      case 'light':
        return '#154150';
      case 'neutral':
      default:
        return '#000000';
    }
  }};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  background-color: ${({ theme, color, disabled }) => (disabled ? theme.colors.neutral : theme.colors[color])};
`;

/*
  :hover {
    ${({ theme, color, disabled }) =>
      disabled
        ? null
        : css`
            color: ${() => (color === 'secondary' ? '#ffffff' : null)};
            background-color: ${() => {
              if (color === 'secondaryDark') return 'secondary';
              const darkColor = `${color}Dark` as 'primaryDark' | 'secondaryDark' | 'neutralDark';
              return theme.colors[darkColor];
            }};
          `}
  }
  */
