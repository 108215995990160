import React, { useState } from 'react';
import { useAction, navigation } from 'State';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';
import { Button } from 'Components/Button';
import { Icon } from 'Components/Icon';

import background from './background.png';
import * as styled from './styled';

const Landing = () => {
  const [t, keys] = useTranslate();
  const goNext = useAction(navigation.goNext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsAccepted, setShowTermsAccepted] = useState(false);
  return (
    <>
      {showTermsAccepted && (
        <styled.modal>
          <styled.modalContent>
            <styled.modalClose>
              <styled.modalCloseIcon onClick={() => setShowTermsAccepted(false)}>
                <Icon name="close" />
              </styled.modalCloseIcon>
            </styled.modalClose>
            <styled.modalTitle>{t(keys.landing.termns.title)}</styled.modalTitle>
            <styled.modalText>{t(keys.landing.termns.text)}</styled.modalText>
          </styled.modalContent>
        </styled.modal>
      )}
      <styled.background image={background}>
        <Page>
          <NavBar />
          <Content>
            <styled.content>
              <styled.intro>Skin care that works for you.</styled.intro>
              <styled.detail>{t(keys.landing.detail1)}</styled.detail>
              <styled.detail>{t(keys.landing.detail2)}</styled.detail>
              <styled.detail>{t(keys.landing.detail3)}</styled.detail>
              <styled.checkbox>
                <styled.input onClick={() => setTermsAccepted(!termsAccepted)}>
                  <Icon name={termsAccepted ? 'checkOn' : 'checkOff'} />
                </styled.input>
                <styled.termsText>
                  I accept the&nbsp;
                  <styled.link onClick={() => setShowTermsAccepted(true)}>{t(keys.landing.termns.link)}</styled.link>
                </styled.termsText>
              </styled.checkbox>
              <styled.actions>
                <Button color="primary" size="large" onClick={goNext} disabled={!termsAccepted}>
                  {t(keys.landing.start)}
                </Button>
              </styled.actions>
            </styled.content>
          </Content>
        </Page>
      </styled.background>
    </>
  );
};

export default Landing;
