import RevieveSDK, { Component } from '@revieve/sdk';

export type Environment = 'prod' | 'test';

let revieveSDK: RevieveSDK;
export const initialize = ({
  partnerId,
  userId,
  environment,
  trackerId,
}: {
  partnerId: string;
  userId: string;
  environment: string;
  trackerId: string;
}) => {
  const SDKEnvironment = environment === 'prod' ? 'prod' : 'test';
  revieveSDK = new RevieveSDK(partnerId, false, SDKEnvironment);
  revieveSDK.setUserId(userId);
  revieveSDK.analytics.addTrackers([{ provider: 'ga', prefix: 'RevieveSDK', account: trackerId }]);
};

export const getRevieveSDK = () => {
  if (!revieveSDK) throw new Error('RevieveSDK is not initialized');
  return revieveSDK;
};

interface Configuration {
  components?: Component[];
  cv?: any;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface SDKConfiguration extends Configuration {}
export const getSeralizableState = (): SDKConfiguration => {
  const { configuration, cv } = getRevieveSDK().getSeralizableState();
  return { configuration, cv };
};
export const hydrateState = (state: SDKConfiguration) => getRevieveSDK().hydrateState(state);
