import React from 'react';
import styled from 'styled-components';
import { heavyFont, lightFont, boldFont } from 'Styles/fonts';

export const background = styled.div<{ image: string }>`
  height: 100%;
  background-color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : null)};
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 45%;
`;

export const title = styled.div`
  justify-self: center;
  align-self: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 60px;
`;

const ContentInner = styled.div`
  width: 100%;
`;

export const content: React.FC = ({ children }) => (
  <ContentWrapper>
    <ContentInner>{children}</ContentInner>
  </ContentWrapper>
);

export const intro = styled.div`
  ${heavyFont}
  font-size: 50px;
  line-height: 50px;
  color: #404040;
  padding-bottom: 15px;
  max-width: 400px;
`;

export const detail = styled.div`
  ${lightFont}
  font-size: 28px;
  display: flex;
  align-items: center;
  margin: 0.5em 0;
`;

export const checkbox = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 54px 0;
`;

export const input = styled.div`
  font-size: 28px;
  margin-right: 6px;
  height: 25px;
  width: 25px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
`;

export const termsText = styled.p`
  padding: 6px 0 0 10px;
`;

export const link = styled.div`
  ${boldFont}
  color: ${({ theme }) => theme.colors.primary}
;
display: inline;
`;

export const actions = styled.div`
  padding: 15px 0;
  display: flex;
`;

export const modal = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`;

export const modalContent = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  width: 470px;
  margin: auto;
  padding-bottom: 50px;
`;

export const modalClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const modalCloseIcon = styled.div`
  width: 25px;
  margin-top: 20px;
  margin-right: 30px;
`;

export const modalTitle = styled.div`
  ${boldFont}
  padding: 0 50px;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 24px;
`;

export const modalText = styled.div`
  padding: 0 50px;
  font-size: 18px;
  line-height: 33px;
`;
