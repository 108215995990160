import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { OnStateInitialized } from 'State';
import * as serviceWorker from './serviceWorker';
import * as revieveService from 'Services/revieve';
import * as analyticsService from 'Services/analytics';

import 'Styles/Fonts/fonts.css';
import './index.css';

const selectedSrote = '15667';
const stores = {
  '15667': {
    partnerId: 'JrW9W4TDjr',
  },
};
const store = stores[selectedSrote];

export const onStateInitialized: OnStateInitialized = store => {
  const state = store.getState();
  if (state.selfie.sdkConfiguration) {
    revieveService.hydrateState(state.selfie.sdkConfiguration);
  }
};

const partnerId = store.partnerId;
const userId = 'userId';
const trackerId = 'UA-22921035-4'; // TODO: CHANGE TRACKERID

revieveService.initialize({ environment: 'prod', partnerId, userId, trackerId });

analyticsService.initialize({ debug: false });
analyticsService.addTrackers({
  trackers: [
    {
      provider: 'GA',
      name: 'default',
      id: trackerId,
      enableSystemEvents: false,
      avoidSettingUserId: true,
    },
  ],
  userId,
  partnerId,
});

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, rootElement);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
