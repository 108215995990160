import styled from 'styled-components';
import { heavyFont } from 'Styles/fonts';

export const questionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

export const question = styled.div`
  ${heavyFont}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-top: 60px;
`;

export const questionSelector = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const options = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const link = styled.span`
  line-height: 2em;
  text-decoration: underline;
  cursor: pointer;
`;

export const result = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const resultText = styled.div``;

export const resultSkinType = styled.div`
  ${heavyFont}
  margin-top: 38px;
  font-size: 50px;
  color: #000000;
`;
