import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { Pages, Page } from 'Pages';
import { State, ThunkAction } from '../index';

interface NavigationState {
  pages: Pages;
  currentPageIndex: number;
}

const initialState: NavigationState = {
  pages: [
    'landing',
    'skintype',
    'skinConcerns',
    'age',
    'location',
    'selfieInstructions',
    'selfie',
    'result',
    'recommendedProducts',
  ],
  currentPageIndex: 0,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    startNavigation(state, action: PayloadAction<Pages>) {
      state.pages = action.payload;
      state.currentPageIndex = 0;
    },
    goToIndex(state, action: PayloadAction<number>) {
      const index = action.payload;
      if (index >= 0 || index <= state.pages.length - 1) {
        state.currentPageIndex = action.payload;
      }
    },
  },
});

export const currentPageSelector = createSelector(
  [({ navigation }: State) => navigation.currentPageIndex, ({ navigation }: State) => navigation.pages],
  (currentPageIndex, pages) => pages[currentPageIndex],
);

export const isFirstPageSelector = createSelector(
  [({ navigation }: State) => navigation.currentPageIndex],
  currentPageIndex => currentPageIndex <= 0,
);

export const isLastPageSelector = createSelector(
  [({ navigation }: State) => navigation.currentPageIndex, ({ navigation }: State) => navigation.pages],
  (currentPageIndex, pages) => currentPageIndex >= pages.length - 1,
);

const isSelectablePage = ({ page, state }: { page: Page; state: State }) => {
  switch (page) {
    case 'result':
      return state.selfie.source !== 'questions';

    default:
      return true;
  }
};

const getNextSelectablePageIndex = ({
  index,
  pages,
  incrementer,
  state,
}: {
  index: number;
  pages: Pages | [];
  incrementer: number;
  state: State;
}): number | null => {
  const nextPageIndex = index + incrementer;
  if (nextPageIndex < 0 || nextPageIndex >= pages.length) return null;
  const nextPage = pages[nextPageIndex];
  if (isSelectablePage({ page: nextPage, state })) return nextPageIndex;
  return getNextSelectablePageIndex({ index: nextPageIndex, pages, incrementer, state });
};

const Navigate = (incrementer: number): ThunkAction => async (dispatch, getState) => {
  const state = getState();
  const {
    navigation: { currentPageIndex, pages },
  } = state;
  const nextSelectablePageIndex = getNextSelectablePageIndex({ index: currentPageIndex, pages, incrementer, state });
  if (nextSelectablePageIndex === null) return;

  dispatch(navigationSlice.actions.goToIndex(nextSelectablePageIndex));
};

export const goNext = () => Navigate(1);
export const goBack = () => Navigate(-1);

export const { startNavigation } = navigationSlice.actions;
export const { reducer } = navigationSlice;
