import React, { FunctionComponent, useState, useEffect } from 'react';
import Weather, { WeatherItem } from './Weather';

import * as styled from './styled';
interface PlaceInfo {
  area?: string;
  city?: string;
  country?: string;
  displayName?: string;
  lat?: number;
  lon?: number;
  uvIndex?: number;
  pollution?: number;
  humidity?: number;
}
export interface LocationInputProps {
  placeInfo?: PlaceInfo;
  suggestedPlaces: {
    description: string;
    placeId: string;
  }[];
  onSearch: (value: string) => void;
  onSelect: (options: { placeId: string }) => void;
  translations: {
    info?: string;
    placeholder: string;
    uvIndex: string;
    humidity: string;
    pollution: string;
    uvIndexInfo?: string;
    humidityInfo?: string;
    pollutionInfo?: string;
  };
}
const Location: FunctionComponent<LocationInputProps> = ({
  placeInfo: { displayName, uvIndex, humidity, pollution } = {},
  onSearch,
  suggestedPlaces,
  onSelect,
  translations,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue, onSearch]);

  const handleEdit = () => {
    setSearchValue('');
    setEditing(true);
  };

  const handleSelect = (placeId: string) => {
    setEditing(false);
    onSelect({ placeId });
  };

  return (
    <styled.wrapper>
      {suggestedPlaces.length > 0 && (
        <styled.suggestedPlaces>
          {suggestedPlaces.map(({ placeId, description }) => (
            <styled.suggestedPlace key={placeId} onClick={() => handleSelect(placeId)}>
              {description}
            </styled.suggestedPlace>
          ))}
        </styled.suggestedPlaces>
      )}
      <styled.inputWrapper onClick={!editing ? handleEdit : undefined}>
        <label htmlFor="">Location</label>
        {editing ? (
          <styled.input
            type="text"
            placeholder={translations.placeholder}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        ) : (
          <styled.input type="text" value={displayName || '...'} onChange={() => {}} />
        )}
      </styled.inputWrapper>
      {translations.info && <styled.info>{translations.info}</styled.info>}
      <Weather>
        <WeatherItem
          value={uvIndex ? `${uvIndex}` : '...'}
          label={translations.uvIndex}
          icon="uv"
          description={translations.uvIndexInfo}
        />
        <WeatherItem
          value={humidity ? `${humidity}%` : '...'}
          label={translations.humidity}
          icon="humidity"
          description={translations.humidityInfo}
        />
        <WeatherItem
          value={pollution ? `${pollution}` : '...'}
          label={translations.pollution}
          icon="pollution"
          description={translations.pollutionInfo}
        />
      </Weather>
    </styled.wrapper>
  );
};

export default Location;
