import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Gender = 'female' | 'male' | 'ratherNotSay';
type SkinType = 'dry' | 'oily' | 'sensitive' | 'normal' | 'combination' | 'unknown';
export type ProductType = 'premium' | 'dermatological';
type SkinConcern =
  | 'wrinkles'
  | 'eyebags'
  | 'redness'
  | 'dull'
  | 'aging'
  | 'acne'
  | 'unevenTone'
  | 'pores'
  | 'lossOfFirmess';
export type Brand =
  | 'No7'
  | 'YGS'
  | 'Botanics'
  | 'Olay'
  | 'LOreal'
  | 'Neutrogena'
  | 'Cerave'
  | 'Cetaphil'
  | 'LaRochePosay'
  | 'Vichy'
  | 'Bioderma'
  | 'Avene'
  | 'Walgreens';

type GeoLocation = { latitude: number; longitude: number };
export interface UserDataInputState {
  gender?: Gender;
  skinType?: SkinType;
  skinConcerns: SkinConcern[];
  productTypes: ProductType[];
  brands: Brand[];
  age: number;
  location?: GeoLocation;
  eyebags?: number;
  darkSpots?: number;
  wrinkles?: number;
}

const initialState: UserDataInputState = {
  gender: 'female',
  skinType: undefined,
  skinConcerns: [],
  productTypes: [],
  brands: [],
  age: 0,
  location: undefined,
  eyebags: undefined,
  darkSpots: undefined,
  wrinkles: undefined,
};

const userInputDataSlice = createSlice({
  name: 'userInputData',
  initialState,
  reducers: {
    selectSkinType(state, action: PayloadAction<SkinType>) {
      state.skinType = action.payload;
    },
    selectSkinConcern(state, action: PayloadAction<SkinConcern>) {
      const skinConcern = action.payload;
      if (!state.skinConcerns.includes(skinConcern)) {
        state.skinConcerns.push(skinConcern);
      }
    },
    unselectSkinConcern(state, action: PayloadAction<SkinConcern>) {
      state.skinConcerns = state.skinConcerns.filter(skinConcerns => skinConcerns !== action.payload);
    },
    selectProductType(state, action: PayloadAction<ProductType>) {
      const productType = action.payload;
      if (!state.productTypes.includes(productType)) {
        state.productTypes.push(productType);
      }
    },
    unselectProductType(state, action: PayloadAction<ProductType>) {
      state.productTypes = state.productTypes.filter(productType => productType !== action.payload);
    },
    resetProductTypes(state) {
      state.productTypes = [];
    },
    selectBrand(state, action: PayloadAction<Brand>) {
      const brand = action.payload;
      if (!state.brands.includes(brand)) {
        state.brands.push(brand);
      }
    },
    unselectBrand(state, action: PayloadAction<Brand>) {
      state.brands = state.brands.filter(brand => brand !== action.payload);
    },
    resetBrands(state) {
      state.brands = [];
    },
    selectGender(state, action: PayloadAction<Gender>) {
      state.gender = action.payload;
    },
    selectAge(state, action: PayloadAction<string>) {
      const age = parseInt(action.payload);
      if (!isNaN(age)) state.age = age;
    },
    selectLocation(state, action: PayloadAction<GeoLocation>) {
      state.location = action.payload;
    },
    selectEyebags(state, action: PayloadAction<number>) {
      state.eyebags = action.payload;
    },
    selectDarkSpots(state, action: PayloadAction<number>) {
      state.darkSpots = action.payload;
    },
    selectWrinkles(state, action: PayloadAction<number>) {
      state.wrinkles = action.payload;
    },
  },
});

export const { reducer } = userInputDataSlice;
export const {
  selectSkinType,
  selectSkinConcern,
  unselectSkinConcern,
  selectProductType,
  unselectProductType,
  resetProductTypes,
  selectBrand,
  unselectBrand,
  resetBrands,
  selectGender,
  selectAge,
  selectLocation,
  selectEyebags,
  selectDarkSpots,
  selectWrinkles,
} = userInputDataSlice.actions;
