import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useStateProvider } from 'State';
import { I18n, en } from 'Locale';
import { ThemeProvider, mapTheme } from 'Styles/theme';
import { getDefaultFont } from 'Styles/fonts';
import { Router } from 'Pages';

const urlParams = new URLSearchParams(document.location.search || '');
const urlLocale = urlParams.get('lang') || '';

type Locale = 'en';
const locale: Locale = ['en'].includes(urlLocale) ? (urlLocale as Locale) : 'en';
const messages = { en };

const GlobalStyle = createGlobalStyle<{ locale: Locale }>`
  body {
   ${getDefaultFont({ locale })};
  }
`;

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <>
      <GlobalStyle locale={locale} />
      <ThemeProvider theme={mapTheme({ locale })}>
        <I18n locale={locale} messages={messages[locale]} allowMissing>
          <Router />
        </I18n>
      </ThemeProvider>
    </>
  );
};

const AppContainer: React.FC<AppProps> = props => {
  const StateProvider = useStateProvider();
  return (
    <StateProvider>
      <App {...props} />
    </StateProvider>
  );
};

export default AppContainer;
