import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ThunkAction, State } from '../index';
import * as revieveService from 'Services/revieve';
import { routines, priceGroups, Routine, PriceGroup, Category } from './configuration';
import { delay } from 'Utils/delay';
import { Brand, ProductType } from '../userInputData';

export type RecommendedProducts = Record<PriceGroup, Record<Category, revieveService.Product>>;

type Status = 'onHold' | 'fetching' | 'success' | 'error';
export interface RecommendedProductsState {
  status: Status;
  products?: RecommendedProducts;
  historyId: string;
  error?: string;
  selectedRoutine: Routine;
  selectedPriceGroup: PriceGroup;
}

const initialState: RecommendedProductsState = {
  status: 'onHold',
  products: undefined,
  historyId: '',
  error: undefined,
  selectedRoutine: 'cleanse',
  selectedPriceGroup: 'normal',
};

const recommendedProductsSlice = createSlice({
  name: 'recommendedProducts',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    request(state) {
      state.status = 'fetching';
      state.error = undefined;
    },
    requestSuccess(state, action: PayloadAction<{ products: revieveService.RecommendedProducts; historyId: string }>) {
      const { products = {}, historyId } = action.payload;
      state.status = 'success';
      state.products = products as RecommendedProducts;
      state.historyId = historyId;
    },
    requestError(state, action: PayloadAction<{ error: string }>) {
      const { error } = action.payload;
      state.status = 'error';
      state.error = error;
    },
    selectRoutine(state, action: PayloadAction<{ routine: Routine }>) {
      state.selectedRoutine = action.payload.routine;
    },
    selectPriceGroup(state, action: PayloadAction<{ priceGroup: PriceGroup }>) {
      state.selectedPriceGroup = action.payload.priceGroup;
    },
  },
});

const backendMatch: Record<Brand, string[]> = {
  No7: ['No7'],
  YGS: ['YourGoodSkin'],
  Botanics: ['Botanics'],
  Olay: [
    'Olay',
    'Olay Age Defying',
    'Olay Complete',
    'Olay Professional ProX',
    'Olay Regenerist',
    'Olay Regenerist Luminous',
    'Olay Serums',
    'Olay Total Effects',
  ],
  LOreal: [
    `L'Oreal Paris Youth Code`,
    `L'Oreal Paris Visible Lift`,
    `L'Oreal Paris True Match`,
    `L'Oreal Paris Sublime`,
    `L'Oreal Paris Skin Expertise`,
    `L'Oreal Paris Revitalift`,
    `L'Oreal Paris Pure-Clay Mask`,
    `L'Oreal Paris Pure-Clay Cleanser`,
    `L'Oreal Paris Pure Sugar Scrub`,
    `L'Oreal Paris Men's Expert`,
    `L'Oreal Paris Ideal Clean`,
    `L'Oreal Paris Hydra Genius`,
    `L'Oreal Paris Collagen`,
    `L'Oreal Paris Age Perfect`,
    `L'Oreal Paris`,
    `L'Oreal Dermo-Expertise`,
  ],
  Neutrogena: [
    'Neutrogena',
    'Neutrogena Natural',
    'Neutrogena Rapid Clear',
    'Neutrogena Clear Pore',
    'Neutrogena Ageless Intensives',
    'Neutrogena Healthy Skin',
    'Neutrogena Man',
  ],
  Cerave: ['CeraVe'],
  Cetaphil: ['Cetaphil'],
  LaRochePosay: ['La Roche-Posay', 'La Roche-Posay Anthelios', 'La Roche-Posay Toleriane', 'La Roche - Posay Effaclar'],
  Vichy: ['Vichy'],
  Bioderma: ['BIODERMA'],
  Avene: ['Avene'],
  Walgreens: ['Walgreens', 'Walgreens Beauty'],
};

const brandsPerProductType: Record<ProductType, Brand[]> = {
  premium: ['No7', 'LaRochePosay', 'Vichy', 'Bioderma', 'Avene'],
  dermatological: ['Cerave', 'LaRochePosay', 'Vichy', 'Bioderma', 'Avene'],
};

const mapBrands = (brands: Brand[]) => [
  ...new Set(brands.reduce((acc, brand) => [...acc, ...backendMatch[brand]], [] as string[])),
];

export const mapProductTypeBrands = (productType: ProductType[]) => [
  ...new Set(productType.reduce((acc, productType) => [...acc, ...brandsPerProductType[productType]], [] as Brand[])),
];

const mapBrandsIncluded = ({ filterBrands, productType }: { filterBrands: Brand[]; productType: ProductType[] }) => {
  if (filterBrands.length === 0 && productType.length === 0) return [];
  if (productType.length === 0) return mapBrands(filterBrands);

  const brandsAllowedByProductType = mapProductTypeBrands(productType);
  if (filterBrands.length === 0) return mapBrands(brandsAllowedByProductType);

  return mapBrands(filterBrands.filter(brand => brandsAllowedByProductType.includes(brand)));
};

export const fetchProducts = (): ThunkAction => async (dispatch, getState) => {
  const { userInputData } = getState();
  const { request, requestSuccess, requestError } = recommendedProductsSlice.actions;
  dispatch(request());
  try {
    const { age, gender, skinType, skinConcerns, eyebags, darkSpots, wrinkles, location } = userInputData;
    const filters: revieveService.RecommendedProductsFilters = {
      age,
      gender: gender === 'ratherNotSay' ? 'female' : gender,
      skinType: skinType === 'unknown' ? undefined : skinType,
      customAttributes: skinConcerns.map(concern => `${concern}_ui`),
      eyebags,
      darkSpots,
      wrinkles,
      locationLatitude: location?.latitude,
      locationLongitude: location?.longitude,
      brandsIncluded: mapBrandsIncluded({
        filterBrands: userInputData.brands,
        productType: userInputData.productTypes,
      }),
    };

    const [{ products, historyId }] = await Promise.all([
      revieveService.getRecommendedProducts({
        routines: routines as revieveService.Routines,
        filters,
        priceGroups: priceGroups as revieveService.PriceGroups,
      }),
      delay(2000),
    ]);

    dispatch(requestSuccess({ products, historyId }));
  } catch (error) {
    dispatch(requestError({ error }));
  }
};

const getBestOf = (products: (Product | undefined)[]) =>
  products.reduce((product1, product2) => {
    const score1 = product1?.score || 0;
    const score2 = product2?.score || 0;
    return score2 > score1 ? product2 : product1;
  });

const groupRoutine = ({ name, products }: { name: Routine; products: Product[] }) => {
  switch (name) {
    case 'cleanse':
      return [
        getBestOf([products[0], products[1], products[2], products[3]]),
        getBestOf([products[4], products[5]]),
        getBestOf([products[6], products[7]]),
        getBestOf([products[8], products[9], products[10]]),
      ];
    case 'treat':
      return [
        getBestOf([products[0], products[1], products[2]]),
        getBestOf([products[3], products[4], products[5]]),
        getBestOf([products[6], products[7], products[8]]),
        getBestOf([products[9], products[10], products[11], products[12]]),
      ];
    default:
      return products;
  }
};

export type Product = revieveService.Product;
export const routinesSelector = createSelector(
  [
    ({ recommendedProducts }: State) => recommendedProducts.products,
    ({ recommendedProducts }: State) => recommendedProducts.selectedPriceGroup,
    ({ userInputData }: State) => userInputData,
  ],
  (products, selectedPriceGroup, userInputData) =>
    routines.reduce<Record<Routine, Product[]>>((acc, { name }) => {
      const recommendedProducts = products
        ? revieveService.mapRoutineProducts({
            routines: routines as revieveService.Routines,
            selectedRoutine: name,
            products,
            selectedPriceGroup,
            userInputData,
          })
        : [];
      const groupedRecommendedProducts = groupRoutine({ name, products: recommendedProducts });
      const nonEmptyRecommendedProducts = groupedRecommendedProducts.filter(
        product => product !== undefined,
      ) as Product[];
      const productList = nonEmptyRecommendedProducts.sort((product1, product2) =>
        product1.score >= product2.score ? -1 : 1,
      );
      return {
        ...acc,
        [name]: productList,
      };
    }, {} as Record<Routine, Product[]>),
);

export const selectedRoutineProductsSelector = createSelector(
  [routinesSelector, ({ recommendedProducts }: State) => recommendedProducts.selectedRoutine],
  (routines, selectedRoutine) => routines[selectedRoutine] ?? [],
);

export const routineProductsSelector = (routine: Routine) =>
  createSelector([routinesSelector], routines => routines[routine] ?? []);

export const { selectRoutine, reset } = recommendedProductsSlice.actions;
export const { reducer } = recommendedProductsSlice;
