import RevieveSDK, { SkinType, Gender, HairColor, EyeColor } from '@revieve/sdk';
import { getRevieveSDK } from './initialization';

type Routine = {
  name: string;
  configurations: Record<string, Record<string, string[]>>;
};

export type Routines = Routine[];
export type PriceGroups = Partial<Record<string, Record<string, [number, number]>>>;

export type RecommendedProductsFilters = {
  customAttributes?: string[];
  skinType?: SkinType;
  gender?: Gender;
  hair_color?: HairColor;
  eye_color?: EyeColor;
  skin_tone?: string;
  age?: number;
  locationLatitude?: number;
  locationLongitude?: number;
  wrinkles?: number;
  eyebags?: number;
  darkSpots?: number;
  redness?: number;
  rocasea?: boolean;
  acne?: boolean;
  eczema?: boolean;
  hyperpigmentation?: boolean;
  oilyness?: boolean;
  dull_tired?: boolean;
  psoriasis?: boolean;
  organic?: boolean;
  anti_age?: boolean;
  fragrance_free?: boolean;
  paraben_free?: boolean;
  sun_protection?: boolean;
  skin_brightening?: boolean;
  skin_firming?: boolean;
  brandsIncluded?: string[];
};

const getCategoriesFromRoutines = ({ routines }: { routines: Routines }) => {
  const categoriesCount: { [key: string]: number } = {};
  routines.forEach(routine => {
    return Object.values(routine.configurations).forEach(configuration => {
      return Object.values(configuration).forEach(value => {
        const categoryCountForRoutine = value.reduce((acc: { [key: string]: number }, curr: string) => {
          if (!acc[curr]) {
            acc[curr] = 1;
          } else {
            acc[curr] += 1;
          }
          return acc;
        }, {});
        Object.keys(categoryCountForRoutine).forEach((category: string) => {
          if (!categoriesCount[category] || categoriesCount[category] < categoryCountForRoutine[category]) {
            categoriesCount[category] = categoryCountForRoutine[category];
          }
          return categoriesCount;
        });
      });
    });
  });
  return Object.keys(categoriesCount).reduce((categories: string[], category: string) => {
    for (let i = 0; i < categoriesCount[category]; i++) {
      categories.push(category);
    }
    return categories;
  }, []);
};

export interface Product {
  name: string;
  category: string;
  description: string;
  manufacturer: string;
  price: number;
  url: string;
  score: number;
  filter_match?: string[];
  id: string;
  parent_id?: string;
  image: string;
  extra_attrs?: number;
  tags?: string[];
}

export type RecommendedProducts = Record<string, Record<string, Product>>;

export const getRecommendedProducts = async ({
  routines,
  filters,
  priceGroups,
}: {
  routines: Routine[];
  filters: RecommendedProductsFilters;
  priceGroups: PriceGroups;
}): Promise<{ products: RecommendedProducts; historyId: string }> => {
  getRevieveSDK().clearUIConfiguration();
  getRevieveSDK().setCategories(getCategoriesFromRoutines({ routines }));
  getRevieveSDK().setCustomAttribute('brand_includes', filters.brandsIncluded);
  getRevieveSDK().setCustomAttribute('priceGroups', priceGroups);
  filters.customAttributes &&
    filters.customAttributes.forEach(customAttribute => getRevieveSDK().setCustomAttribute(customAttribute, 1));
  getRevieveSDK().setSkintype(filters.skinType ? RevieveSDK.skinTypes[filters.skinType.toUpperCase()] : undefined);
  getRevieveSDK().setGender(
    filters.gender
      ? RevieveSDK.gender[filters.gender.toUpperCase()] || RevieveSDK.gender.FEMALE
      : RevieveSDK.gender.FEMALE,
  );
  getRevieveSDK().setHairColor(filters.hair_color ? RevieveSDK.hairColor[filters.hair_color.toUpperCase()] : undefined);
  getRevieveSDK().setEyeColor(filters.eye_color ? RevieveSDK.eyeColor[filters.eye_color.toUpperCase()] : undefined);
  getRevieveSDK().setSkintoneColor(filters.skin_tone);
  getRevieveSDK().setAge(filters.age ? filters.age : undefined);
  getRevieveSDK().setLat(filters.locationLatitude ? undefined : filters.locationLatitude);
  getRevieveSDK().setLon(filters.locationLongitude ? undefined : filters.locationLongitude);

  filters.wrinkles && getRevieveSDK().setWrinkles(filters.wrinkles);
  filters.eyebags && getRevieveSDK().setEyebags(filters.eyebags);
  filters.darkSpots && getRevieveSDK().setDarkSpots(filters.darkSpots);
  filters.redness && getRevieveSDK().setRedness(filters.redness);

  filters.rocasea && getRevieveSDK().setRocasea(true);
  filters.acne && getRevieveSDK().setAcne(true);
  filters.eczema && getRevieveSDK().setEczema(true);
  filters.hyperpigmentation && getRevieveSDK().setHyperpigmentation(true);
  filters.oilyness && getRevieveSDK().setOilyness(true);
  filters.dull_tired && getRevieveSDK().setDullTired(true);
  filters.psoriasis && getRevieveSDK().setPsoriasis(true);
  filters.organic && getRevieveSDK().setOrganic(true);
  filters.anti_age && getRevieveSDK().setAntiAge(true);
  filters.fragrance_free && getRevieveSDK().setFragranceFree(true);
  filters.paraben_free && getRevieveSDK().setParabenFree(true);
  filters.sun_protection && getRevieveSDK().setSunProtection(true);
  filters.skin_brightening && getRevieveSDK().setSkinBrightening(true);
  filters.skin_firming && getRevieveSDK().setSkinFirming(true);

  const products: RecommendedProducts = await getRevieveSDK().PR.getRecommendedProducts();
  return { products, historyId: getRevieveSDK().PR.getHistoryId() };
};

export const mapRoutineProducts = ({
  routines,
  selectedRoutine,
  userInputData,
  products,
  selectedPriceGroup,
}: {
  routines: Routine[];
  selectedRoutine: string;
  userInputData: any;
  products: RecommendedProducts;
  selectedPriceGroup: string;
}) => {
  const routine = routines.find(({ name }) => name === selectedRoutine);
  if (!routine) return [];
  const { configurations } = routine;
  const [userInputDataKey] = Object.keys(configurations);
  const userInputDataValue = userInputData[userInputDataKey];
  const categories =
    (configurations[userInputDataKey] && configurations[userInputDataKey][`${userInputDataValue}_mapper`]) ||
    (configurations[userInputDataKey] && configurations[userInputDataKey][userInputDataValue]) ||
    (configurations[userInputDataKey] && configurations[userInputDataKey].default_mapper) ||
    (configurations[userInputDataKey] && configurations[userInputDataKey].default);
  if (!categories) return [];
  return categories.map(category => products?.[selectedPriceGroup]?.[category]);
};
