import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { analyseImage, AnalyseImageResult, SDKConfiguration, getSeralizableState } from 'Services/revieve';
import { ThunkAction } from '../index';

type State = 'taking' | 'analysing' | 'done' | 'error';
type Source = 'video' | 'questions' | 'upload';
interface Message {
  code: number | string;
  description: string;
}

type Findings = Required<AnalyseImageResult>['findings'];
export interface SelfieState {
  source: Source;
  status: State;
  image: string;
  errors?: Message[];
  warnings?: Message[];
  findings: Findings;
  sdkConfiguration?: SDKConfiguration;
}

const initialState: SelfieState = {
  source: 'video',
  status: 'taking',
  image: '',
  errors: undefined,
  warnings: undefined,
  findings: {} as Findings,
  sdkConfiguration: undefined,
};

const selfieSlice = createSlice({
  name: 'selfie',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    selectSource(state, action: PayloadAction<Source>) {
      state.source = action.payload;
    },
    analyzeImageRequest(state, action: PayloadAction<{ image: string }>) {
      const { image } = action.payload;
      state.status = 'analysing';
      state.image = image;
      state.errors = undefined;
      state.warnings = undefined;
      state.findings = {} as Findings;
    },
    analyzeImageDone(
      state,
      action: PayloadAction<{ results: AnalyseImageResult; sdkConfiguration: SDKConfiguration }>,
    ) {
      const { results, sdkConfiguration } = action.payload;
      state.sdkConfiguration = sdkConfiguration;
      state.errors = undefined;
      state.warnings = undefined;
      state.findings = {} as Findings;

      if (results.hasErrors) {
        state.status = 'error';
        state.errors = results.warnings;
      } else {
        state.status = 'done';
        state.findings = results.findings ?? ({} as Findings);
        state.warnings = results.warnings;
      }
    },
    noCameraAvailable(state) {
      state.status = 'error';
      state.errors = [
        {
          code: 'NO_CAMERA',
          description: 'No camera available',
        },
      ];
      state.warnings = undefined;
      state.findings = {} as Findings;
    },
  },
});

export const analizeImage = (image: string): ThunkAction => async (dispatch, getState) => {
  const { userInputData } = getState();
  dispatch(selfieSlice.actions.analyzeImageRequest({ image }));
  const results = await analyseImage({ image, gender: userInputData.gender });
  const sdkConfiguration = getSeralizableState();
  dispatch(selfieSlice.actions.analyzeImageDone({ results, sdkConfiguration }));
};

export const uploadSelfie = (image: string): ThunkAction => async dispatch => {
  dispatch(selfieSlice.actions.selectSource('upload'));
  dispatch(analizeImage(image));
};

export const { reset, noCameraAvailable, selectSource } = selfieSlice.actions;
export const { reducer } = selfieSlice;
