import React, { FunctionComponent } from 'react';
import { ContextProvider } from './context';
import SelectorButton, { ButtonProps } from './Button';
import Image, { ImageProps } from './Image';
import Radio, { RadioProps } from './Radio';
import Check, { CheckProps } from './Check';

import * as styled from './styled';

interface SelectComponent<P> extends FunctionComponent<P> {
  Button: FunctionComponent<ButtonProps>;
  Image: FunctionComponent<ImageProps>;
  Radio: FunctionComponent<RadioProps>;
  Check: FunctionComponent<CheckProps>;
}

export interface SelectProps {
  values?: (string | undefined)[];
  layout?: styled.Layout;
  onSelect?: (value: string) => void;
  onUnselect?: (value: string) => void;
}

export const Select: SelectComponent<SelectProps> = ({
  children,
  values = [],
  layout,
  onSelect = () => {},
  onUnselect = () => {},
}) => (
  <ContextProvider value={{ onSelect, selectedValues: values, onUnselect }}>
    <styled.select layout={layout}>{children}</styled.select>
  </ContextProvider>
);

Select.Button = SelectorButton;
Select.Image = Image;
Select.Radio = Radio;
Select.Check = Check;
