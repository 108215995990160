import { css } from 'styled-components';

// Force deploy

const color = '#5F686F';
const fonts = {
  en: {
    light: 'Helvetica Neue LT Std L',
    regular: 'Helvetica Neue LT Std R',
    medium: 'Helvetica Neue LT Std M',
    bold: 'Helvetica Neue LT Std B',
    heavy: 'Helvetica Neue LT Std H',
  },
};

export const getDefaultFont = ({ locale }: { locale: 'en' }) =>
  css`
    font-family: ${fonts[locale].regular};
    color: ${color};
    font-size: 28px;
    line-height: 30px;
  `;

export const lightFont = css`
  font-family: ${({ theme }) => fonts[theme.locale].light};
  color: ${color};
`;

export const regularFont = css`
  font-family: ${({ theme }) => fonts[theme.locale].regular};
  color: ${color};
`;

export const mediumFont = css`
  font-family: ${({ theme }) => fonts[theme.locale].medium};
  color: ${color};
`;

export const boldFont = css`
  font-family: ${({ theme }) => fonts[theme.locale].bold};
  color: ${color};
`;

export const heavyFont = css`
  font-family: ${({ theme }) => fonts[theme.locale].heavy};
  color: ${color};
`;
