import styled, { DefaultTheme } from 'styled-components';

export interface StyledIcon {
  color?: string;
}

export const wrapper = styled('div')<{ theme: DefaultTheme; color?: string }>`
  width: 100%;
  height: 100%;
  color: ${({ color }) => color};
  svg {
    width: 100%;
    height: 100%;
  }
`;
