import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useGlobalState, useAction, navigation } from 'State';
import { useTranslate } from 'Locale';
import { useRevieveResults } from 'Services/revieve';
import { Page, NavBar } from 'Components/Layout';
import { Back } from 'Components/Layout/Actions';

import { Button } from 'Components/Button';
import { FindingName } from 'Services/revieve';
import { Metrics } from './Metrics';

import * as styled from './styled';

export type Metric = { name: FindingName; value: number };

const findingsToShow: FindingName[] = [
  'hyperpigmentation',
  'wrinkles',
  'redness',
  'texture',
  'skinShine',
  'acne',
  'smoothness',
  'eyebags',
  'darkcircles',
];

const Result = () => {
  const [t, keys] = useTranslate();
  const [resultsElement, { style, highlightFinding, deHighlightFinding }] = useRevieveResults();
  const image = useGlobalState(({ selfie }) => selfie.image);
  const findings = useGlobalState(state => state.selfie.findings);
  const [selectedFindig, selectFinding] = useState<FindingName | null>(null);
  const goBack = useAction(navigation.goBack);
  const goNext = useAction(navigation.goNext);

  const healthMetrics = useMemo(
    () =>
      findingsToShow.reduce<Metric[]>((acc, name) => {
        const findingValue = findings[name] ?? null;
        if (findingValue === null) return acc;
        const value = findingValue;
        return [...acc, { name, value }];
      }, []),
    [findings],
  );

  const handleOnSelect = useCallback((metric: Metric) => selectFinding(metric.name), []);

  useEffect(() => {
    if (selectedFindig === null) deHighlightFinding();
    else highlightFinding(selectedFindig);
  }, [selectedFindig, highlightFinding, deHighlightFinding]);

  return (
    <>
      <styled.cvResultsWrapper>
        <styled.cvResults ref={resultsElement} style={style} image={image} />
      </styled.cvResultsWrapper>
      <Page>
        <NavBar />
        <styled.wrapper>
          <styled.metrics>
            <Metrics metrics={healthMetrics} onSelect={handleOnSelect} />
          </styled.metrics>
          <styled.actions>
            <Back onClick={goBack} />
            <Button color="primary" onClick={goNext}>
              {t(keys.result.goToProducts)}
            </Button>
          </styled.actions>
        </styled.wrapper>
      </Page>
    </>
  );
};

export default Result;
