import React from 'react';
import { useTranslate } from 'Locale';
import { Page, NavBar, Content } from 'Components/Layout';

import * as styled from './styled';

export const Error: React.FC<{ image: string; errors: string[]; onRetry: () => void }> = ({
  image,
  errors,
  onRetry,
}) => {
  const [t, keys] = useTranslate();
  return (
    <>
      <styled.image src={image} alt="selfie" />
      <Page>
        <NavBar />
        <Content>
          <styled.contentWrapper>
            {errors.map((error, key) => (
              <styled.message key={key}>{error}</styled.message>
            ))}

            <styled.link onClick={onRetry}>{t(keys.selfie.error.retry)}</styled.link>
          </styled.contentWrapper>
        </Content>
      </Page>
    </>
  );
};
