import React from 'react';
import * as recommendedProducts from 'State/recommendedProducts';
import { useTranslate } from 'Locale';

import * as styled from './styled';

export const Product: React.FC<{ product: recommendedProducts.Product; bestMatch: boolean }> = ({
  product,
  bestMatch,
}) => {
  const { name, image, manufacturer } = product;
  const [t, keys] = useTranslate();
  return (
    <styled.wrapper>
      {bestMatch && <styled.bestMatch>{t(keys.recommendedProducts.bestMatch)}</styled.bestMatch>}
      <styled.image src={image} alt={name} />
      <styled.manufacturer>{manufacturer}</styled.manufacturer>
      <styled.name>{name}</styled.name>
    </styled.wrapper>
  );
};
