import { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { State } from './index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type useAction = <T extends (...args: any[]) => any>(action: T) => T;
export const useAction: useAction = action => {
  const dispatch = useDispatch();
  return useCallback(bindActionCreators(action, dispatch), [dispatch, action]);
};

export const useGlobalState = <TSelected>(selector: (state: State) => TSelected) =>
  useSelector<State, TSelected>(selector, shallowEqual);
