import React, { useState, useRef } from 'react';
import { useAction, useGlobalState, userInputData, recommendedProducts, State } from 'State';
import { useTranslate } from 'Locale';
import { mapProductTypeBrands } from 'State/recommendedProducts';
import { Icon } from 'Components/Icon';
import { Select } from 'Components/Select';
import { Button } from 'Components/Button';

import * as styled from './styled';

const DisabledTooltip: React.FC<{ disabled: boolean }> = ({ children, disabled }) =>
  disabled ? (
    <span
      data-tip="Clear other filters<br/>to enable this one."
      data-html="true"
      data-place="left"
      data-effect="solid"
      data-iscapture="true"
    >
      {children}
    </span>
  ) : (
    <>{children}</>
  );

const Section: React.FC<{ title: string }> = ({ children, title }) => {
  const [openned, setOpenned] = useState(false);

  return (
    <styled.section>
      <styled.sectionHeader onClick={() => setOpenned(!openned)}>
        <styled.sectionTitle openned={openned}>{title}</styled.sectionTitle>
        <styled.sectionIcon>
          <Icon name={openned ? 'arrowBottom' : 'arrowTop'} />
        </styled.sectionIcon>
      </styled.sectionHeader>
      {openned && <styled.sectionOptions>{children}</styled.sectionOptions>}
    </styled.section>
  );
};

type Gender = Exclude<State['userInputData']['gender'], undefined>;
type ProductType = State['userInputData']['productTypes'][0];
type Brand = State['userInputData']['brands'][0];
export const Filters: React.FC<{ show: boolean; onHide: () => void }> = ({ show, onHide }) => {
  const [t, keys] = useTranslate();
  const container = useRef<HTMLDivElement>(null);
  const fetchProducts = useAction(recommendedProducts.fetchProducts);
  const productTypes = useGlobalState(({ userInputData }) => userInputData.productTypes);
  const selectProductType = useAction(userInputData.selectProductType);
  const unselectProductType = useAction(userInputData.unselectProductType);
  const resetProductTypes = useAction(userInputData.resetProductTypes);
  const brands = useGlobalState(({ userInputData }) => userInputData.brands);
  const selectBrand = useAction(userInputData.selectBrand);
  const unselectBrand = useAction(userInputData.unselectBrand);
  const resetBrands = useAction(userInputData.resetBrands);
  const gender = useGlobalState(({ userInputData }) => userInputData.gender);
  const selectGender = useAction(userInputData.selectGender);

  const handleClearFilters = () => {
    resetProductTypes();
    resetBrands();
    fetchProducts();
  };

  const filtersCount = productTypes.length + brands.length;

  const isProductTypeFilterSelected = productTypes.length > 0;
  const enabledBrands = mapProductTypeBrands(productTypes);

  return (
    <>
      <styled.courtine show={show} />
      <styled.filters show={show}>
        <styled.close>
          <styled.closeIcon onClick={onHide}>
            <Icon name="close" />
          </styled.closeIcon>
        </styled.close>
        <styled.content>
          <styled.title>
            {t(keys.recommendedProducts.filters)}
            {filtersCount ? ` (${filtersCount})` : null}
          </styled.title>
          <styled.sections ref={container}>
            <Section title={t(keys.recommendedProducts.productType)}>
              <Select
                values={productTypes}
                onSelect={value => {
                  selectProductType(value as ProductType);
                  fetchProducts();
                }}
                onUnselect={value => {
                  unselectProductType(value as ProductType);
                  fetchProducts();
                }}
                layout="full"
              >
                <Select.Check size="small" value="premium">
                  {t(keys.recommendedProducts.premium)}
                </Select.Check>
                <Select.Check size="small" value="dermatological">
                  {t(keys.recommendedProducts.dermatological)}
                </Select.Check>
              </Select>
            </Section>
            <Section title={t(keys.recommendedProducts.brand)}>
              <styled.tooltip offset={{ top: 8 }} clickable />
              <Select
                values={brands}
                onSelect={value => {
                  selectBrand(value as Brand);
                  fetchProducts();
                }}
                onUnselect={value => {
                  unselectBrand(value as Brand);
                  fetchProducts();
                }}
                layout="full"
              >
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('No7')}>
                  <Select.Check
                    size="small"
                    value="No7"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('No7')}
                    data-tip="hello world"
                  >
                    {t(keys.recommendedProducts.No7)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('YGS')}>
                  <Select.Check
                    size="small"
                    value="YGS"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('YGS')}
                  >
                    {t(keys.recommendedProducts.YGS)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Botanics')}>
                  <Select.Check
                    size="small"
                    value="Botanics"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Botanics')}
                  >
                    {t(keys.recommendedProducts.Botanics)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Olay')}>
                  <Select.Check
                    size="small"
                    value="Olay"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Olay')}
                  >
                    {t(keys.recommendedProducts.Olay)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('LOreal')}>
                  <Select.Check
                    size="small"
                    value="LOreal"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('LOreal')}
                  >
                    {t(keys.recommendedProducts.LOreal)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Neutrogena')}>
                  <Select.Check
                    size="small"
                    value="Neutrogena"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Neutrogena')}
                  >
                    {t(keys.recommendedProducts.Neutrogena)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Cerave')}>
                  <Select.Check
                    size="small"
                    value="Cerave"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Cerave')}
                  >
                    {t(keys.recommendedProducts.Cerave)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Cetaphil')}>
                  <Select.Check
                    size="small"
                    value="Cetaphil"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Cetaphil')}
                  >
                    {t(keys.recommendedProducts.Cetaphil)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('LaRochePosay')}>
                  <Select.Check
                    size="small"
                    value="LaRochePosay"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('LaRochePosay')}
                  >
                    {t(keys.recommendedProducts.LaRochePosay)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Vichy')}>
                  <Select.Check
                    size="small"
                    value="Vichy"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Vichy')}
                  >
                    {t(keys.recommendedProducts.Vichy)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Bioderma')}>
                  <Select.Check
                    size="small"
                    value="Bioderma"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Bioderma')}
                  >
                    {t(keys.recommendedProducts.Bioderma)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Avene')}>
                  <Select.Check
                    size="small"
                    value="Avene"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Avene')}
                  >
                    {t(keys.recommendedProducts.Avene)}
                  </Select.Check>
                </DisabledTooltip>
                <DisabledTooltip disabled={isProductTypeFilterSelected && !enabledBrands.includes('Walgreens')}>
                  <Select.Check
                    size="small"
                    value="Walgreens"
                    disabled={isProductTypeFilterSelected && !enabledBrands.includes('Walgreens')}
                  >
                    {t(keys.recommendedProducts.Walgreens)}
                  </Select.Check>
                </DisabledTooltip>
              </Select>
            </Section>
            <Section title="Gender">
              <Select
                values={[gender]}
                onSelect={value => {
                  selectGender(value as Gender);
                  fetchProducts();
                }}
                layout="full"
              >
                <Select.Radio size="small" value="female">
                  Female
                </Select.Radio>
                <Select.Radio size="small" value="male">
                  Male
                </Select.Radio>
              </Select>
            </Section>
          </styled.sections>
          <styled.actions>
            <Button color="primary" onClick={handleClearFilters}>
              {t(keys.recommendedProducts.clearFilters)}
            </Button>
          </styled.actions>
        </styled.content>
      </styled.filters>
    </>
  );
};
