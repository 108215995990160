import styled from 'styled-components';

export const questionSelector = styled.div`
  flex: 1;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 9px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
