import styled, { css } from 'styled-components';

export const wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const selectedDot = css`
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 26px;
  top: -10px;
  background-color: #ffffff;
  border: solid 4px ${({ theme }) => theme.colors.primaryDark};
`;

const noSelectedDot = css`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  position: relative;
  top: -8px;
  background-color: #cfcfcf;
`;

const doneDot = css`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  position: relative;
  top: -8px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
`;

export const dot = styled.div<{ selected: boolean; done: boolean }>`
  ${({ selected, done }) => (selected ? selectedDot : done ? doneDot : noSelectedDot)};
`;

export const separator = styled.div<{ done: boolean }>`
  border-top: solid 4px ${({ done, theme }) => (done ? theme.colors.primaryDark : '#cfcfcf')};
  width: 40px;
`;
