import React, { useContext } from 'react';

interface Context {
  onSelect: (value: string) => void;
  onUnselect: (value: string) => void;
  selectedValues: (string | undefined)[];
}

const context = React.createContext<Context>({ onSelect: () => {}, onUnselect: () => {}, selectedValues: [] });

export const ContextProvider = context.Provider;
export const useSelectActions = () => useContext(context);
