import React from 'react';
import { useGlobalState, useAction, selfie, navigation } from 'State';
import { useTranslate } from 'Locale';
import { Questions } from './Questions';
import { TakeSelfie } from './TakeSelfie';
import { Analysing } from './Analysing';
import { Error } from './Error';
import { Confirmation } from './Confirmation';

interface Message {
  code: number | string;
  description: string;
}

const useMessageMapper = () => {
  const [t] = useTranslate();
  return (messages: Message[] = []) =>
    messages.map(({ code, description }) => {
      const key = `selfie.messages.CV_CODE_${code}`;
      const translatedMessage = t(key);
      return translatedMessage !== key ? translatedMessage : description;
    });
};

const Selfie: React.FC = () => {
  const source = useGlobalState(({ selfie }) => selfie.source);
  const status = useGlobalState(({ selfie }) => selfie.status);
  const image = useGlobalState(({ selfie }) => selfie.image);
  const warnings = useGlobalState(({ selfie }) => selfie.warnings);
  const errors = useGlobalState(({ selfie }) => selfie.errors);
  const analizeSelfie = useAction(selfie.analizeImage);
  const noCameraAvailable = useAction(selfie.noCameraAvailable);
  const reset = useAction(selfie.reset);
  const goNext = useAction(navigation.goNext);
  const goBack = useAction(navigation.goBack);
  const mapMessages = useMessageMapper();

  if (source === 'questions') return <Questions />;

  const handleRetry = () => {
    if (source === 'upload') goBack();
    reset();
  };

  switch (status) {
    case 'taking':
      return <TakeSelfie onCancel={goBack} onError={noCameraAvailable} onTakeSelfie={analizeSelfie} />;

    case 'analysing':
      return <Analysing image={image} />;

    case 'error':
      return <Error image={image} errors={mapMessages(errors)} onRetry={handleRetry} />;

    case 'done':
      return <Confirmation image={image} warnings={mapMessages(warnings)} onRetry={handleRetry} onContinue={goNext} />;

    default:
      return null;
  }
};

export default Selfie;
