import React from 'react';
import { useTranslate } from 'Locale';
import { Icon } from 'Components/Icon';
import { Metric as MetricType } from '../Result';

import * as styled from './styled';

type Label = 'low' | 'medium' | 'high';
const mapValue = (value: number): { color: string; label: Label } => {
  if (value < 30) return { color: '#168366', label: 'low' };
  if (value < 70) return { color: '#ac6502', label: 'medium' };
  return { color: '#e31837', label: 'high' };
};

export const Metric: React.FC<{
  selected?: boolean;
  onSelect: () => void;
  name: MetricType['name'];
  value: number;
}> = ({ selected = false, onSelect, name, value }) => {
  const [t, keys] = useTranslate();
  const { color, label } = mapValue(value);
  return (
    <styled.wrapper selected={selected} onClick={onSelect}>
      <styled.metric>{t(keys.result[name])}</styled.metric>
      <styled.iconWrapper>
        <styled.icon>
          <Icon name={name} />
        </styled.icon>
      </styled.iconWrapper>
      <styled.label color={color}>{t(keys.result[label])}</styled.label>
    </styled.wrapper>
  );
};
