import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'Services/geoIp';
import { getPlaceIdFromLocation, getInfoFromPlaceId, getGeoSuggestedPlaces } from 'Services/maps';
import { getWeather } from 'Services/weather';
import LocationInput, { LocationInputProps } from './LocationInput';

interface PlaceInfo {
  area?: string;
  city?: string;
  country?: string;
  lat?: number;
  lon?: number;
  uvIndex?: number;
  pollution?: number;
  humidity?: number;
  displayName?: string;
}

interface SuggestedPlace {
  description: string;
  placeId: string;
}

export interface LocationProps {
  defaultLocation?: { lat: number; lon: number };
  environment: 'test' | 'prod';
  onChange: ({ latitude, longitude }: { latitude: number; longitude: number }) => void;
  translations: LocationInputProps['translations'];
}
const LocationContainer: FunctionComponent<LocationProps> = ({
  defaultLocation,
  environment,
  onChange,
  translations,
}) => {
  const userLocation = useLocation({ location: defaultLocation, environment });
  const [placeId, setPlaceId] = useState<string | undefined>();
  const [placeInfo, setPlaceInfo] = useState<PlaceInfo | undefined>();
  const [suggestedPlaces, setSuggestedPlaces] = useState<SuggestedPlace[]>([]);

  useEffect(() => {
    const { lat, lon } = userLocation;
    if (lat === undefined || lon === undefined) return;
    const fetchPlaceId = async () => {
      const placeId = await getPlaceIdFromLocation({ lat, lon });
      setPlaceId(placeId);
    };
    fetchPlaceId();
  }, [userLocation]);

  useEffect(() => {
    if (placeId === undefined) return;
    const fetchInfo = async () => {
      const info = await getInfoFromPlaceId({ placeId });
      const { area, city, country, lat, lon } = info;
      const { uvIndex, pollution, humidity } = await getWeather({ environment, area, city, country, lat, lon });
      const displayName = city || area || country;
      setPlaceInfo({ area, city, country, lat, lon, uvIndex, pollution, humidity, displayName });
      onChange({ latitude: lat, longitude: lon });
    };
    fetchInfo();
  }, [placeId, environment, onChange]);

  const handleSearch = useCallback(async (value: string) => {
    const places = await getGeoSuggestedPlaces(value);
    setSuggestedPlaces(places);
  }, []);

  const handleSelect = ({ placeId }: { placeId: string }) => {
    setPlaceId(placeId);
    setPlaceInfo(undefined);
    setSuggestedPlaces([]);
  };

  return (
    <LocationInput
      placeInfo={placeInfo}
      onSearch={handleSearch}
      suggestedPlaces={suggestedPlaces}
      onSelect={handleSelect}
      translations={translations}
    />
  );
};

export default LocationContainer;
