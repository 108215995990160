import { ThemeProvider as styledComponentsThemeProvider, ThemeContext } from 'styled-components';
import { useContext } from 'react';

export interface Theme {
  locale: 'en';
  colors: {
    light: string;
    lightDark: string;
    neutral: string;
    neutralDark: string;
    primary: string;
    primaryDark: string;
    secondary: string;
    secondaryDark: string;
    ghost: string;
    ghostDark: string;
    pinkDark: string;
  };
}

export const ThemeProvider = styledComponentsThemeProvider;
export const useTheme = () => useContext(ThemeContext);

export const mapTheme = ({ locale }: { locale: 'en' }) => ({
  locale,
  colors: {
    light: '#cfeff5',
    lightDark: '#02a0c0',
    neutral: '#cccccc',
    neutralDark: '#999999',
    primary: '#02a0c0',
    primaryDark: '#01728e',
    secondary: '#f9dfe5',
    secondaryDark: '#822d44',
    ghost: 'transparent',
    ghostDark: 'transparent',
    pinkDark: 'rgb(130,45,68)',
  },
});
