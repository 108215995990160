import React from 'react';

import * as styled from './styled';

export const Content: React.FC = ({ children }) => <styled.content>{children}</styled.content>;

export const FullscreenContent: React.FC = ({ children }) => (
  <styled.fullScreenContent>{children}</styled.fullScreenContent>
);

export const PaddingContent: React.FC = ({ children }) => <styled.paddingContent>{children}</styled.paddingContent>;
